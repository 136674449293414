export const SavedTimers = [
    {
        timerKey: 't0',
        timeHMS: {
            h: 0,
            m: 15,
            s: 0
        }
    },
    {
        timerKey: 't1',
        timeHMS: {
            h: 1,
            m: 30,
            s: 0
        }
    },
    {
        timerKey: 't2',
        timeHMS: {
            h: 0,
            m: 30,
            s: 0
        }
    },
    {
        timerKey: 't3',
        timeHMS: {
            h: 15,
            m: 15,
            s: 15
        }
    }
];
import React from "react";
import styled from "styled-components";
import { Sizes } from "../../styles/Sizes";
import { Colors } from "../../styles/Colors";
import FounderImg from "../../images/Founder.jpg";
import { Wrapper } from "../styling-components/Wrapper";
import { Adorn } from "../styling-components/TEXT/Adorn";

const Founderbox = styled.div`
  padding: calc(${Sizes.BaseUnit} * 3) 0;
  background-color: ${Colors.ThirdMagenta};
  height: auto;
`;

const FounderContainer = styled.div`
  display: flex;
  max-height: 90%;
  margin: 0 auto;
  justify-content: center;

  @media only screen and (min-width: 550px) and (max-width: 750px) {
    max-width: 90vw;
    display: block;
  }

  @media only screen and (max-width: 549px) {
    flex-direction: column;
    width: 85vw;
  }
`;

const FounderPic = styled.img`
 // width: 25vw;
  max-width: 430px;
  height: 100%;
  margin-right: calc(${Sizes.BaseUnit} * 2.3);

  @media only screen and (min-width: 901px) and (max-width: ${Sizes.Wrapper}) {
    width: 40vw;
  }

  @media only screen and (min-width: 550px) and (max-width: 900px) {
    width: 45vw;
    float: inline-start;
    margin-bottom: calc(${Sizes.BaseUnit} * 1.5);
  }

  @media only screen and (min-width: 401px) and (max-width: 549px) {
    width: 60vw;
    order: 2;
    margin: calc(${Sizes.BaseUnit} * 2) auto;
  }

  @media only screen and (max-width: 400px) {
    width: 65vw;
    order: 2;
    margin: calc(${Sizes.BaseUnit} * 2) auto;
  }
`;

const FounderText = styled.div`
  max-width: calc(${Sizes.Wrapper} * 0.41);

  h2,
  p,
  b,
  em,
  ${Adorn} {
    color: white;
  }

  p {
    font-weight: 300;
  }

  b {
    font-weight: 500;
  }

  p:first-of-type {
    margin-bottom: ${Sizes.ParagraphSpacing};
  }

  ${Adorn} {
    font-size: 6rem;
    line-height: 5rem;
    font-weight: 300;
    margin: calc(${Sizes.BaseUnit} * 0.8) calc(${Sizes.BaseUnit} * 3.5)
      calc(${Sizes.BaseUnit} * 1.5);

    @media only screen and (max-width: 900px) {
      text-align: center;
      margin: calc(${Sizes.BaseUnit} * 0.8) 0 calc(${Sizes.BaseUnit} * 0.8);
    }

    @media only screen and (max-width: 370px) {
      font-size: 5rem;
      text-align: center;
      margin: calc(${Sizes.BaseUnit} * 0.8) 0 calc(${Sizes.BaseUnit} * 0.8);
    }
  }

  h4 {
    margin-bottom: ${Sizes.ParagraphSpacing};
  }

  @media only screen and (min-width: 550px) and (max-width: 750px) {
    max-width: 100%;
  }
`;

export default function Founder() {
  return (
    <Founderbox>
      <Wrapper>
        <FounderContainer>
          <FounderPic src={FounderImg} />

          <FounderText>
            <h2>Möt Linnéa,</h2>
            <h4>
              <em>Grundare & kreativ ledare</em>
            </h4>

            <em>
              <p>
                <b>
                  "God design mer än bara este&shy;tik, - det hand&shy;lar om
                  att för&shy;stå män&shy;ni&shy;skor, att kunna sätta på sig
                  någon annans skor för att skapa något skrädd&shy;ar&shy;sytt
                  som känns unikt, per&shy;son&shy;ligt och som verk&shy;ligen
                  rep&shy;res&shy;en&shy;terar dem.
                </b>
              </p>

              <p>
                För mig är det det som design hand&shy;lar om, - att se bortom
                vad folk gör och säger för att hitta mön&shy;ster i
                be&shy;te&shy;enden och förstå be&shy;hoven bakom för att kunna
                upp&shy;fylla dem. Det handlar om att vara när&shy;var&shy;ande,
                ly&shy;hörd och med&shy;veten om de behov och känslor som andra
                per&shy;soner upp&shy;lever. Det går långt ut&shy;över att bara
                lyssna – det inne&shy;bär att man sätter på sig någon annans
                skor och går en mil i dom för att upp&shy;leva värl&shy;den
                genom deras ögon.
              </p>

              <p>
                Design är mitt verk&shy;tyg att kunna hjälpa andra att berätta
                sin unika historia genom design, form, färg och inno&shy;vation.
                För design handlar inte bara om hur något ser ut, - det handlar
                om att skapa en upp&shy;lev&shy;else som berör djup&shy;are
                nivåer av för&shy;stå&shy;else."
              </p>
            </em>

            <Adorn>"Design är empati."</Adorn>
          </FounderText>
        </FounderContainer>
      </Wrapper>
    </Founderbox>
  );
}

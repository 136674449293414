import styled from 'styled-components'
import { Wrapper } from '../../components/styling-components/Wrapper'
import ContactForm from '../../components/main/ContactForm'
import { ContactItem } from '../../content-info/ContactH2H4'
import { Sizes } from '../../styles/Sizes'

const PrintBox = styled.div`
  h4 {
    margin-bottom: calc( ${ Sizes.BaseUnit } *2);
  }
`

export default function Print() {
  return (
    <PrintBox>
      <Wrapper>
        <ContactForm h2item={ ContactItem[2].h2 } h4item={ ContactItem[2].h4 }/>
      </Wrapper>
    </PrintBox>
  )
}
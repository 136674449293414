import styled from "styled-components";

import { Sizes } from "../../styles/Sizes";
import { Colors, LinkColor } from "../../styles/Colors";

import { ButtonDBG } from "./buttons/Button";
import CheckMark, { CheckWrapper } from "../icons/CheckMark";
import IconsContainer, { IconContainer } from "./SoMeIconsContainer";
import { SoMeButton } from "../styling-components/ICON-Styles/SoMeButton";

const ModalBox = styled.div`
  display: block;
  z-index: 3000;
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalH1 = styled.h3`
  display: flex;
  align-items: center;

  font-size: 24px;
  letter-spacing: 0.04rem;

  ::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 300%;
    bottom: calc(-${Sizes.IconGap} * 0.3);
    left: 50%;
    transform: translateX(-50%);

    background-color: ${Colors.Black};

    @media only screen and (max-width: 545px) {
      max-width: 13.5rem;
    }
  }

  margin-bottom: ${Sizes.IconGap};
  position: relative;

  ${CheckWrapper} {
    position: absolute;
    left: 100%;
  }
`;

const ModalH2 = styled.p`
  font-weight: 300;
  font-size: 18px;
`;

const ModalContent = styled.div`
  z-index: 3500;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background-color: ${Colors.White};
  border-radius: ${Sizes.BorderRadius};

  ${ButtonDBG} {
    width: fit-content;
    margin-top: calc(${Sizes.IconGap} * 3);
  }

  ${IconContainer} {
    margin-top: ${Sizes.IconGap};
    ${SoMeButton} {
      a {
        svg {
          /* fill: ${LinkColor.LinkLightBG}; */
          fill: ${Colors.Blue};
        }

        svg:hover {
          fill: ${LinkColor.LinkHoverLightBG};
        }

        svg:active {
          fill: ${LinkColor.LinkActiveLightBG};
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding: calc(${Sizes.BaseUnit} * 4) calc(${Sizes.BaseUnit} * 4);
    width: 55vw;
    min-width: calc(${Sizes.BaseUnit} * 28);
    max-width: calc(${Sizes.BaseUnit} * 38);

    margin: 20vh auto;

    ${ButtonDBG} {
      height: calc(${Sizes.BaseUnit} * 2.3);
      padding: 0 calc(${Sizes.BaseUnit} * 1.8);
    }
  }

  @media only screen and (max-width: 767px) {
    padding: calc(${Sizes.BaseUnit} * 2) calc(${Sizes.BaseUnit} * 1.8)
      calc(${Sizes.BaseUnit} * 3);
    width: 90vw;
    min-width: calc(${Sizes.BaseUnit} * 20);
    max-width: calc(${Sizes.BaseUnit} * 22);
    margin: 10vh auto;

    ${ButtonDBG} {
      font-size: 17px;
      font-weight: 500;
      height: calc(${Sizes.BaseUnit} * 2.5);
      padding: 0 calc(${Sizes.BaseUnit} * 2);
    }
  }
`;

export default function Modal({ showModal, closeModal }) {
  if (!showModal) {
    return <></>;
  }

  return (
    <ModalBox>
      <ModalContent>
        <ModalH1>
          Skickat! <CheckMark />
        </ModalH1>
        <ModalH2>
          Tack för ditt med&shy;del&shy;ande! Vi kommer att åter&shy;komma till
          dig inom de närmsta 1-2 arbets&shy;dagarna. Häng gärna med oss på
          sociala medier så länge för att ta del av ytter&shy;ligare nyheter &
          upp&shy;dateringar!
        </ModalH2>

        <IconsContainer />

        <ButtonDBG onClick={closeModal}>Stäng</ButtonDBG>
      </ModalContent>
    </ModalBox>
  );
}

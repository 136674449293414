import React from "react";
import styled, { keyframes } from "styled-components";
import ToggleVisibleClickOutside from "../../hooks/useToggleVisibleClickOutside";
import { Flex } from "../styling-components/CONTAINERS/Flex";
import Arrow from "../icons/Arrow";
import { Sizes } from "../../styles/Sizes";
import { Line } from "../lego-static-parts/lines/Line";
import { Colors } from "../../styles/Colors";

/** Animation - CollapseContent */
const rollingDown = keyframes`
  from {
    height: 0%;
    opacity: 0;
  }
  to {
    height: 100%;
    opacity: 1;
  }
`
const rollingUp = keyframes`
  from {
    height: 100%;
    opacity: 1;
  }
  to {
    height: 0%;
    opacity: 0;
  }
`

export const CollapseContainer = styled.div`
  margin: 0 auto;

  ${Flex} {
    justify-content: space-between;
    align-items: center;

    h4 {
      padding-right: ${ Sizes.ParagraphSpacing };
    }

    svg {
      transform: ${({isComponentVisible}) => (isComponentVisible ? 'rotate(-180deg)' : 'rotate(0)')};
      transition: transform 0.2s ease;
      transform-origin: 0.6rem;
    }
  }

  & ~ & {
    padding-top: ${ Sizes.BaseUnit };
  }

  ${ Line } {
    background-color: ${ Colors.Grey };
    margin-top: calc( ${ Sizes.BaseUnit } *1);
  }

  // CollapseContainer Media-Width
  @media only screen and (min-width: 1601px) {
    width: 35vw;
  }

  @media only screen and (min-width: 1331px) and (max-width: 1600px) {
    width: 40vw;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1330px) {
    width: 45vw;
  }

  @media only screen and (min-width: 901px) and (max-width: 1200px) {
    width: 55vw;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    width: 75vw;
  }

  @media only screen and (min-width: 450px) and (max-width: 600px) {
    width: 80vw;
  }

  @media only screen and (max-width: 449px) {
    width: 85vw;
  }
`;

const CollapseContent = styled.div`
  animation: ${({isComponentVisible}) => (isComponentVisible ? rollingUp : rollingDown )} .4s ease;
  padding-bottom: calc( ${Sizes.BaseUnit} *.8);

  p {
    font-weight: 300;
  }

  ul {
    column-count: 2;
    margin-top: calc( ${Sizes.ParagraphSpacing} *.6);

    li {
        font-family: quasimoda, new-atten, brandon-grotesque, mr-eaves-modern, apparat-light, proxima-nova, montserrat, lato, -apple-system, 'Open Sans', sans-serif;
        font-weight: 300;
        hyphens: manual;
        margin-right: calc( ${Sizes.ParagraphSpacing} *1.5);
    }

    li ~ li {
        margin-bottom: calc( ${Sizes.ParagraphSpacing} *.3);
    }
  }
`

export default function Collapsable({ item }) {
  const { 
    ref, 
    isComponentVisible, 
    setIsComponentVisible 
  } = ToggleVisibleClickOutside(false);

  return (       
    <CollapseContainer key={ item.itemkey } ref={ ref } isComponentVisible={ isComponentVisible } onClick={() => setIsComponentVisible( isComponentVisible ? false : true )}>
        <Flex>
            <h4 dangerouslySetInnerHTML={{ __html: item.Q }}/>
            <Arrow/>
        </Flex>
        
        { isComponentVisible ? 
            <CollapseContent dangerouslySetInnerHTML={{ __html: item.A }}/> : <></>
        }

        <Line/>
    </CollapseContainer>
  )
}
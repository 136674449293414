import { Link } from 'react-router-dom';
import styled from "styled-components";

import { ReactComponent as LogoLieDown } from '../../../images/GM_LieDown_Black.svg';
/* import { Sizes } from '../../styles/Sizes'; */
import { Colors } from '../../../styles/Colors';

export const HeaderLogoBox = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    
    a {
        height: 100%;
    }
`

export const LogoLieDownBlack = styled(LogoLieDown)`
    width: 100%;

    & path {
        fill: ${ Colors.LightBG };
    }
`

export default function HeaderLogo() {
    return (
      <HeaderLogoBox>
          <Link to='/'>
              <LogoLieDownBlack/>
          </Link>
      </HeaderLogoBox>
    )
}
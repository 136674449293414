import React from "react";
import styled from "styled-components";

import SiljanBottomBG from "../../../../images/FrontEndComponentLowerBG2.0.png";

export const LowerImg = styled.img`
  z-index: 700;
  position: absolute;
  bottom: 0;
  width: 2048px;
  height: 1014.7px;
  background: url(${SiljanBottomBG});
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: inset(1px 0);
`;

export default function LowerSiljan() {
  return (
      <LowerImg/>
  );
}
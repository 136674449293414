import React from 'react'
import styled from 'styled-components';

import { Colors, LinkColor } from '../../styles/Colors';

export const BurgerIconBox = styled.div`
  display: flex;
  align-items: center;
  z-index: 11000;

  height: 100%;
  width: 1.6rem;

  position: ${({ isComponentVisible }) => isComponentVisible ? 'absolute' : ''};
  top: ${({ isComponentVisible }) => isComponentVisible ? '0' : ''};

`

export const Burger = styled.button`
  z-index: 11500;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  width: 1.6rem;
  height: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    display: block;
    width: 1.5rem;
    height: 0.1rem;
    background: ${({ isComponentVisible }) => isComponentVisible ? Colors.VivaMagenta : Colors.Black};

    :active {
      background: ${({ isComponentVisible }) => isComponentVisible ? LinkColor.LinkHoverDarkBG : Colors.Black};
    }
  
    transition: all 0.3s linear;
    transform-origin: 0rem;

    &:first-child {
      transform: ${({ isComponentVisible }) => isComponentVisible ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ isComponentVisible }) => isComponentVisible ? '0' : '1'};
      transform: ${({ isComponentVisible }) => isComponentVisible ? 'translateX(20px)' : 'translateX(0)'};
    }

    &:nth-child(3) {
      transform: ${({ isComponentVisible }) => isComponentVisible ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export default function HamburgerIcon({ isComponentVisible }) {
  return (
    <BurgerIconBox data-testid='BurgerIconBox'>
      <Burger isComponentVisible = { isComponentVisible } data-testid='BurgerBox'> 
        <div />
        <div />
        <div />
      </Burger>
    </BurgerIconBox>
  )
}
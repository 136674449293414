import React from "react";
import styled from "styled-components";
import { MobileColors } from "../../../styles/Colors";

const RangeCircleSvg = styled.svg`
  z-index: 300;
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  //margin: auto;
  height: 100%;
  width: 100%;
  // aspect-ratio: 1;
  // transform: rotate(0.5turn);

  border-radius: 50%;
`;

const RangeCircle = styled.circle`
  // z-index: 1500;
  // height: 100%;
  // width: 100%;
  fill: transparent;

  //stroke-dasharray: 590; //774;
  //stroke-dashoffset: 100;
  stroke-linecap: round;
  //stroke-width: 20px;

  //stroke-dashoffset: {({ Lilspan }) => (Lilspan ? Lilspan : Lilspan)};
  transform: rotate(270deg);
  transform-origin: center center;
  transition: "stroke-dashoffset 0.5s ease 0s";

  //stroke: linear-gradient(white, white) padding-box, linear-gradient(to bottom right, #743ad5, #d53a9d) border-box;
  // background: linear-gradient(white, white) padding-box,
  // linear-gradient(to bottom right, #743ad5, #d53a9d) border-box;
`;

const RangeShadow = styled.div`
  z-index: 700;
  position: absolute;
  // top: 0;
  // left: 0;
  height: 100%;
  width: 100%;
  margin: 8px auto;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: inset ${MobileColors.DarkShadow},
    inset ${MobileColors.InsetLightShadow}, inset ${MobileColors.LightShadow};
`;

export default function CountdownRange(props) {
  const gradient = [
    { stop: 0.0, color: "#00bc9b" },
    { stop: 0.25, color: "#743ad5" },
    { stop: 0.5, color: "#d53a9d" },
    { stop: 1, color: "#5eaefd" },
  ];
  const suffix = "%";


  const circleWidth = props.circleWidth;
  const circleStrokeWidth = props.circleStrokeWidth;
  const center = props.center;

  const circleHeight = circleWidth; // || center + center * Math.cos(reduction * Math.PI);

  const rc = props.rc;
  const circumference = props.circumference; // 565.4866776461628
  const offset = props.offset; //circumference / progress;

  //console.log("Progress: " + circleStrokeWidth);
  //console.log("offset: " + offset);

  /**
   * 
    const rc = center - circleStrokeWidth / 2;
    const circumference = Math.PI * rc * 2; // 565.4866776461628
    const offset = circumference / progress;
   */

  return (
    <RangeShadow>
      <RangeCircleSvg viewBox={`0 0 ${circleWidth} ${circleHeight}`}>
        <defs>
          <linearGradient
            id={"LineGradient"}
            x1="0%"
            y1="15%"
            x2="80%"
            y2="100%"
          >
            {gradient.map(({ stop, color }) => (
              <stop
                key={stop}
                offset={stop * 100 + (suffix || "")}
                stopColor={color}
              />
            ))}
          </linearGradient>
        </defs>
        <RangeCircle
          cx={center}
          cy={center}
          r={rc}
          strokeWidth={circleStrokeWidth}
          strokeDasharray={`${circumference}`}
          strokeDashoffset={offset}
          stroke={`url(#LineGradient)`}
        />
      </RangeCircleSvg>
    </RangeShadow>
  );
}

/**linear-gradient(160deg, #4900f5 0%, #80D0C7 100%);
 *  strokeDashoffset={imeRange}
 *  
 * strokeDashoffset={Lilspan + suffix}
 *
 * rgba(225, 0, 255, 0.5), rgba(255, 0, 212, 0.5), rgba(255, 174, 0, 0.5), rgba(255, 230, 0, 0.5);
 // border-width: 5px;  darkblue, darkorchid
  //border-image: linear-gradient(to bottom right, #743ad5, #b2ff59) 100%;
  // background-color: blue; 
*/

/**--color-primary: #ab79d6;
--color-secondary: #d367c1;
--color-on-background: #c5c5ce;
--color-yellow: #dedf40;
--color-green: #62cb5c;
--color-blue: #00bbcb;
--gradient: var(--color-secondary) 10%,var(--color-yellow) 25%,var(--color-green) 50%,var(--color-blue) 75%,var(--color-primary) 90%;

--gradient-angle: 35deg;
border-image: linear-gradient(var(--gradient-angle,to bottom),var(--gradient)) 1;
border-style: solid;
border-width: 8px;*/
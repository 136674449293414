export const ContactItem = [
    {
        itemkey: 'C0',
        page: 'Contact & Home',
        h2: 'Kontakta Grafikmakeriet,',
        h4: 'din partner för kreativa lösningar när du är i behov av design, tryck, webb, foto, marknads&shy;förings&shy;strategi eller inno&shy;vations&shy;-utveck&shy;ling! Fyll i vårt form&shy;ulär nedan för att snabbt komma igång. Vi är dedi&shy;kerade till att svara på ditt mail så fort vi kan och hjälpa dig med allt från design till innovation.'
    },
    {
        itemkey: 'C1',
        page: 'GraphicProfile',
        h2: 'Behöver du hjälp med din grafiska profil?',
        h4: 'För att lyfta din varumärkes&shy;identitet till nya höjder, är Grafikmakeriet din pålit&shy;liga partner för skräddar&shy;sydd grafisk profil. Oavsett om du är i behov av en ny logo&shy;typ, färg&shy;schema eller andra grafiska element, är vårt dedik&shy;erade team redo att ge ditt varu&shy;märke den visuella styrkan det för&shy;tjänar. Kontakta oss genom att fylla i form&shy;uläret nedan och låt oss till&shy;sammans skapa en grafisk profil som sticker ut och berättar din unika historia.'
    },
    {
        itemkey: 'C2',
        page: 'Print',
        h2: 'Skapa intryck med kvalitetstryck!',
        h4: 'Grafikmakeriet är din partner när det gäller tryck och pre&shy;press. Oavsett om du planerar affisch&shy;produktion, visit&shy;kort eller andra tryck&shy;saker, är vi här för att säker&shy;ställa att ditt budskap kommer fram skarpt och pro&shy;fession&shy;ellt. Fyll i form&shy;uläret nedan för att inleda sam&shy;arbetet och låt oss till&shy;sammans för&shy;vandla dina idéer till imponer&shy;ande tryckta material.'
    },
    {
        itemkey: 'C3',
        page: 'Strategy',
        h2: 'Forma ditt varumärke online eller i tryck!',
        h4: 'Vi special&shy;iserar oss på att skapa och för&shy;stärka varu&shy;märkes&shy;strategier för att säker&shy;ställa att din närvaro på mark&shy;naden är stark och minnes&shy;värd. Oavsett om du behöver en helt ny strategi eller en över&shy;syn av din befint&shy;liga, är vi redo att guida dig. Fyll i form&shy;uläret nedan och låt oss till&shy;sammans bygga en varu&shy;märkes&shy;strategi som speglar din unika identitet och skapar starka kund&shy;relationer.'
    },
    {
        itemkey: 'C4',
        page: 'Webb',
        h2: 'Behöver du hjälp med frågor som rör webben?',
        h4: 'Du är i trygga händer! Vi hjälper dig med allt från webb&shy;ut&shy;veck&shy;ling, till modern design med kraft&shy;full funk&shy;tion&shy;alitet. Börja med att skicka ett meddelande till oss genom att fylla i form&shy;uläret nedan så återkopplar vi till dig så fort som möjligt så att vi till&shy;sammans kan skapa en webb&shy;lösning som inte bara imponerar, utan också engagerar och om&shy;vandlar dina besökare till kunder.'
    },
    {
        itemkey: 'C5',
        page: 'HowTo',
        h2: 'Låt oss börja med att diskutera ditt projekt över en kopp kaffe!',
        h4: 'Vi finns här för att hjälpa dig. Fyll i formuläret nedan så återkommer vi så snart som möjligt för att boka in ett möte.'
    },
    {
        itemkey: 'C6',
        page: 'FAQ',
        h2: 'Hittar du inte svaret på din fråga?',
        h4: 'Ingen fara! Vi finns här för att hjälpa dig. Fyll i formuläret nedan så återkommer vi så snart som möjligt med den information du behöver. Inga frågor är för små eller för stora – vi är här för att göra din upplevelse smidig och informativ.'
    },
    {
        itemkey: 'C7',
        page: 'Offert',
        h2: 'Offert?',
        h4: 'Önskar du en detaljerad och anpassad kostnadsuppskattning för ditt kommande projekt? Vi jobbar för att ge dig en exakt offert som matchar dina behov och mål. Fyll i formuläret nedan med information om ditt projekt så sätter vi ihop en skräddarsydd offert.'
    },
    {
        itemkey: 'C8',
        page: 'Prices',
        h2: 'Är du nyfiken på priser och vill ha mer information?',
        h4: 'Vi strävar efter trans&shy;parent kom&shy;mun&shy;ikation och att skräddarsy lösningar efter dina behov. Fyll i form&shy;uläret nedan så ger vi dig gärna en detalj&shy;erad över&shy;sikt över priser och hur vi kan skapa den bästa lösningen för dig. Låt oss till&shy;sammans hitta den perfekta planen som passar din budget och upp&shy;fyller dina mål.'
    }
]
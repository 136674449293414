import React from 'react'
import styled from 'styled-components'

import Siljan from '../../images/Siljan-RGB.jpg'
import { Sizes } from '../../styles/Sizes'
import { Adorn } from '../styling-components/TEXT/Adorn'
import { BreakLine } from '../lego-static-parts/diverse/Break'


const HeroImg = styled.img`
    z-index: -10;
    pointer-events: none;
    position: absolute;

    left: 0;
`

const CTABox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    height: fit-content;

    ${ Adorn } {
        font-weight: 400;
        line-height: 3.8rem;
    }

    p {
        font-size: 18px;
        font-weight: 300;
    }
`


export const HeroBox = styled.div`
    display: flex;
    position: relative;
    margin: 0 auto;
    max-width: ${ Sizes.Wrapper };

    /** Desktop / Tablet - min 767px */
    @media only screen and (min-width: ${ Sizes.Wrapper }) {
        height: ${ Sizes.HeroHeight };
        justify-content: flex-end;

        ${ HeroImg } {
            width: 100%;
            top: -53%;
        }

        ${ CTABox } {
            width: 45%;
            margin: calc( ${ Sizes.BaseUnit } *2) calc( ${ Sizes.BaseUnit } *7) 0;

            ${ Adorn } {
                font-size: 5.3rem;
/**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }
        }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1199px) {
        height: 70vh;
        max-height: 38rem;
        min-height: 26rem;
        padding: 0 calc( ${ Sizes.BaseUnit } *1);
        justify-content: flex-end;

        ${ HeroImg } {
            width: 100%;
            top: -60%;
            transform: translateX(-2%);
        }

        ${ CTABox } {
            width: 45%;
            margin: calc( ${ Sizes.BaseUnit } *2) calc( ${ Sizes.BaseUnit } *7) 0;

            ${ Adorn } {
                font-size: 4.7rem;
/**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }
        }
    }

    @media only screen and (min-width: 880px) and (max-width: 1023px ) {
        height: 65vh;
        max-height: 32rem;
        min-height: 25rem;
        padding: 0 calc( ${ Sizes.BaseUnit } *1);
        justify-content: flex-end;

        ${ HeroImg } {
            width: 130vw;
            top: -90%;
            transform: translateX(-12%);
        }

        ${ CTABox } {
            width: 50%;
            max-width: 60%;
            margin: calc( ${ Sizes.BaseUnit } *1.9) calc( ${ Sizes.BaseUnit } *7.5) 0;

            ${ Adorn } {
                font-size: 4.6rem;
                line-height: 3.5rem;
/**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }

            ${ BreakLine } {
                display: none;
            }
        } 
    }

    @media only screen and (min-width: 768px) and (max-width: 879px ) {
        height: 70vh;
        max-height: 27rem;
        min-height: 25rem;
        padding: 0 calc( ${ Sizes.BaseUnit } *1);

        ${ HeroImg } {
            width: 130vw;
            top: -60%;
            transform: translateX(-12%);
        }

        ${ CTABox } {
            width: 53%;
            max-width: 60%;
            margin: calc( ${ Sizes.BaseUnit } *1.3) auto;

            ${ Adorn } {
                font-size: 4.3rem;
                line-height: 3.2rem;
/**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }

            ${ BreakLine } {
                display: none;
            }
        } 
    }
    /** ------------------------------------------------------------------------- */
    
    /** iPad Pro 12.9-inch - 6th generation - iOS 17.0 - 16GB */
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (max-aspect-ratio: 10/7) and (orientation: portrait) {
        height: 56vh;
        justify-content: center;

        ${ HeroImg } {
            width: 130vw;
            top: -42%;
            transform: translateX(-13%);
        }

        ${ CTABox } {
            width: 60vw;
            margin-top: calc( ${ Sizes.BaseUnit } *2.3);

            ${ Adorn } {
                font-size: 6.9rem;
                line-height: 5.3rem;
    /**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }

            p {
                font-size: 22px;
            }
        }
    }

    /** iPad Pro 10.5" & 11" */
    @media only screen and (orientation: portrait) and (min-device-width: 834px) and (max-device-width: 1194px) and (max-aspect-ratio: 3/4) {
        height: 54vh;
        justify-content: center;

        ${ HeroImg } {
            width: 135vw;
            top: -31%;
            transform: translateX(-15%);
        }

        ${ CTABox } {
            width: 65vw;
            margin: calc( ${ Sizes.BaseUnit } *0.5 ) auto;

            ${ Adorn } {
                font-size: 6rem;
                line-height: 5rem;
    /**? */            margin-bottom: calc(${ Sizes.IconGap } *1);
            }

            p {
                font-size: 22px;
            }
        }
    }

    @media only screen and (orientation: landscape) and (max-device-width: 834px) and (max-device-width: 1194px) and (min-aspect-ratio: 3/4) {
        ${ HeroImg } {
            width: 100vw;
            top: -55%;
            transform: translateX(-2%);
        }

        ${ CTABox } {
            width: 45%;
            margin: calc( ${ Sizes.BaseUnit } *1.5 ) auto;

            ${ Adorn } {
                font-size: 5rem;
                line-height: 3.8rem;
                margin: calc(${ Sizes.IconGap } *1) 0 0;
            }

            p {
                font-size: 21px;
            }
        }
    }
    /** ------------------------------------------------------------------------- */

    /** Mobile - max 767px */
    @media only screen and (min-width: 641px) and (max-width: 767px ) {
        height: 70vh;
        min-height: 22.5rem;
        max-height: 24rem;

        ${ HeroImg } {
            width: 140vw;
            top: -63%;
            transform: translateX(-14%);
        }

        ${ CTABox } {
            width: 55vw;
            max-width: 60vw;
            margin: calc( ${ Sizes.BaseUnit } *.8) auto;

            ${ Adorn } {
                font-size: 4rem;
                line-height: 3rem;
            }

            p {
                font-size: 17px;
                line-height: 1.45rem;
            }
        } 
    }

    @media only screen and (min-width: 600px) and (max-width: 640px ) {
        height: 70vh;
        min-height: 20rem;
        max-height: 21rem;

        ${ HeroImg } {
            width: 140vw;
            top: -70%;
            transform: translateX(-14%);
        }

        ${ CTABox } {
            width: 55vw;
            max-width: 60vw;
            margin: calc( ${ Sizes.BaseUnit } *.5) auto;

            ${ Adorn } {
                font-size: 3.7rem;
                line-height: 2.8rem;
            }

            p {
                font-size: 17px;
                line-height: 1.45rem;
            }
        } 
    }

    @media only screen and (min-width: 540px) and (max-width: 599px ) {
        height: 75vh;
        min-height: 20rem;
        max-height: 22rem;

        ${ HeroImg } {
            width: 150vw;
            top: -60%;
            transform: translateX(-16.5%);
        }

        ${ CTABox } {
            width: 60vw;
            max-width: 63vw;
            margin: calc( ${ Sizes.BaseUnit } *.5) auto;

            ${ Adorn } {
                font-size: 3.7rem;
                line-height: 2.8rem;
            }

            ${ BreakLine } {
                display: none;
            }

            p {
                font-size: 17px;
                line-height: 1.45rem;
            }
        } 
    }

    @media only screen and (min-width: 486px) and (max-width: 539px ) {
        height: 80vh;
        min-height: 22rem;
        max-height: 27rem;

        ${ HeroImg } {
            width: 170vw;
            top: -38%;
            transform: translateX(-18%);
        }

        ${ CTABox } {
            width: 70vw;
            max-width: 75vw;
            margin: calc( ${ Sizes.BaseUnit } *.9) auto;

            ${ Adorn } {
                font-size: 4.5rem;
                line-height: 3.4rem;
            }

            ${ BreakLine } {
                display: none;
            }

            p {
                font-size: 19px;
            }
        } 
    }

    @media only screen and (min-width: 415px) and (max-width: 485px ) {
        height: 70vh;
        min-height: 30rem;
        max-height: 35rem;

        ${ HeroImg } {
            width: 190vw;
            top: -17%;
            transform: translateX(-19%);
        }

        ${ CTABox } {
            width: 77vw;
            max-width: 80vw;
            margin: calc( ${ Sizes.BaseUnit } *1.7) auto;

            ${ Adorn } {
                font-size: 4.5rem;
                line-height: 3.4rem;
                margin-bottom: calc(${ Sizes.IconGap } *.6);
            }

            ${ BreakLine } {
                display: none;
            }

            p {
                font-size: 20px;
            }
        } 
    }

    @media only screen and (min-width: 361px) and (max-width: 414px) {
        height: 70vh;
        min-height: 30rem;
        max-height: 33rem;

        ${ HeroImg } {
            width: 180vw;
            top: -6%;
            transform: translateX(-20%);
        }

        ${ CTABox } {
            width: 80vw;
            max-width: 85vw;
            margin: calc( ${ Sizes.BaseUnit } *1.5) auto 0;

            ${ Adorn } {
                font-size: 4.2rem;
                line-height: 3.2rem;
                margin-bottom: calc(${ Sizes.IconGap } *.6);
            }

            ${ BreakLine } {
                display: none;
            }

            p {
                font-size: 19.5px;
                line-height: 1.65rem;
            }
        }
    }

    @media only screen and (max-width: 360px) {
        height: 70vh;
        min-height: 28rem;
        max-height: 30rem;

        ${ HeroImg } {
            width: 185vw;
            top: -11%;
            transform: translateX(-21%);
        }

        ${ CTABox } {
            width: 79vw;
            max-width: 85vw;
            margin: calc( ${ Sizes.BaseUnit } *1) auto 0;

            ${ Adorn } {
                font-size: 3.8rem;
                line-height: 3rem;
            }

            ${ BreakLine } {
                display: none;
            }

            p {
                font-size: 17px;
                line-height: 1.65rem;
            }
        }
    }
`

export default function Hero() {    
  return (
    <HeroBox>
        <HeroImg src={ Siljan }/>

        <CTABox>
            <Adorn>"Vi hjälper företag att utvecklas <BreakLine/> till varumärken"</Adorn>
            <p>Varje före&shy;tag har en unik story att berätta. Grafik&shy;makeriet är din lokala design&shy;byrå som hjälper ditt före&shy;tag att hitta sin unika röst och kreativa uttryck för att komm&shy;unicera er story på ett sätt som engagerar och inspirerar.</p>
        </CTABox>
    </HeroBox>
  )
}
import styled from "styled-components";
import { Sizes } from "../../../styles/Sizes";
import { ButtonColor, Colors } from "../../../styles/Colors";

export const ButtonDBG = styled.button`
    height: calc(${ Sizes.BaseUnit } *3);
    
    // width: FYLL PÅ I MAIN COMPONENT ;
    // margin: FYLL PÅ I MAIN COMPONENT ;   

    background-color: ${ ButtonColor.DarkBG };
    border: none;
    border-radius: ${ Sizes.BorderRadius };

    color: ${ Colors.White };
    font-family: 'new-atten', 'apparat', 'quire-sans', 'proxima-nova', 'brandon-grotesque', 'quasimoda', 'montserrat', 'lato', '-apple-system', 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: .9rem;
    letter-spacing: .12rem;
    text-transform: uppercase;

    cursor: pointer;

    &:hover {
        background-color: ${ ButtonColor.HoverDarkBG };
    }

    &:active {
        background-color: ${ ButtonColor.ActiveDarkBG };
    }
`

import React from "react";
import styled from "styled-components";

import SiljanUpperBG from "../../../../images/FrontEndComponentUpperBG2.0.png";

export const SiljanTop = styled.img`
  z-index: 100;
  position: absolute;
  top: 0;
  width: 2048px;
  height: 1014.7px;
  background: url(${SiljanUpperBG});
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: inset(1px 0);
`;

export default function UpperSiljan() {
  return <SiljanTop />;
}

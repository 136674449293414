export const QAitems = [
    {
        itemkey: 'QA0',
        Q: 'Vad kan ni göra?',
        A: '<p>Vi erbjuder våra tjänster till både privat&shy;personer och före&shy;tag inom bland annat:</p><ul><li>Grafisk design</li><li>Pre&shy;press</li><li>Print</li><li>Varumärkes&shy;strategi</li><li>Koncept-, & affärs&shy;ut&shy;veck&shy;ling</li><li>Marknads&shy;förings&shy;strategi</li><li>PR-&shy;konsult&shy;ationer</li><li>Foto&shy;grafi</li><li>Copy&shy;writing</li><li>Webb</li><li>UX/UI</li><li>SEO</li></ul>'
    },
    {
        itemkey: 'QA1',
        Q: 'Har ni något kontor som man kan besöka er på?',
        A: '<p>Nej. Vi tror att bäst resultat uppnås när vi möts i era lokaler! Vi vill få chansen att få känna in ert space när vi gör upp&shy;drag för er. Om det inte skulle fun&shy;gera att ses hos er så hittar vi någon annan in&shy;spirer&shy;ande plats där vi kan mötas.</p>'
    },
    {
        itemkey: 'QA2',
        Q: 'Vad händer om jag inte är nöjd med den första designen?',
        A: '<p>Hos oss får du alltid möjlig&shy;heten att säga vad du tycker om den första skissen. Är det något som känns fel så ser vi till att göra det rätt. När vi sen kommit fram till en skiss du är bekväm med så färdig&shy;ställer vi din design och efter det så ingår det ytter&shy;ligare två korriger&shy;ings&shy;rundor där du får möjlig&shy;het att tycka till om designen. Vid behov av ytter&shy;lig&shy;are korriger&shy;ings&shy;rundor till&shy;kommer en extra kostnad per runda.</p>'
    },
    {
        itemkey: 'QA3',
        Q: 'Hur går arbets&shy;processen till?',
        A: '<p>Vår arbets&shy;process involv&shy;erar en in&shy;led&shy;ande kontakt, ett projekt&shy;möte, skiss&shy;ning och koncept&shy;ual&shy;isering, ett första ut&shy;kast, feed&shy;back och korrig&shy;er&shy;ingar, design&shy;final&shy;isering, god&shy;kän&shy;nande och leverans, samt slut&shy;för&shy;ande och upp&shy;följning. Varje steg syftar till att göra din upp&shy;lev&shy;else personlig och effektiv. Läs mer om hur arbets&shy;processen går till <Link to={ / }>här</Link>.</p>'
    },
    {
        itemkey: 'QA4',
        Q: 'Hur lång tid tar det att slut&shy;föra ett projekt?',
        A: '<p>Projektets tids&shy;ram varierar beroende på dess om&shy;fatt&shy;ning och komplex&shy;itet. Vi disku&shy;terar detta i detalj under vårt projekt&shy;möte för att fast&shy;ställa en real&shy;istisk tids&shy;linje. Enklare projekt kan leve&shy;reras på så kort tid som 1-2 arbetsdagar.</p>'
    },    
    {
        itemkey: 'QA5',
        Q: 'Vad kostar det?',
        A: '<p>Våra priser ink&shy;luderar olika tjänster bero&shy;ende på projektet. För en detalj&shy;erad offert, vän&shy;ligen fyll i vårt kontaktformulär.</p>'
    },
    {
        itemkey: 'QA6',
        Q: 'Kan ni hjälpa till med bråd&shy;skande projekt?',
        A: '<p>Oftast så kan vi det! Vi strävar efter att möta våra kunders behov och kan över&shy;väga bråd&shy;skande projekt. Kontakta oss direkt för att diskutera möjligheterna.</p>'
    },
    {
        itemkey: 'QA7',
        Q: 'Kan ni hjälpa till med att förnya min befint&shy;liga grafiska profil?',
        A: '<p>Själv&shy;klart! Grafikmakeriet är experter på att förnya och för&shy;bättra grafiska profiler för att ge ditt varu&shy;märke en fräsch och modern känsla. Vi är här för att hjälpa till med alla aspekter av din grafiska identitet, ink&shy;lusive vektor&shy;ise&shy;ring av logo&shy;typer om du be&shy;finner dig i den oväntade situa&shy;tionen att sakna original&shy;filer. Vår mål&shy;sätt&shy;ning är att lyfta fram det bästa i ditt varu&shy;märke och skapa en grafisk profil som verk&shy;ligen står ut. Kontakta oss så disku&shy;terar vi hur vi kan förnya och stärka ditt visuella uttryck tillsammans.</p>'
    },
    {
        itemkey: 'QA8',
        Q: 'Kan jag upp&shy;datera min hemsida själv?',
        A: '<p style="margin-bottom: .5rem">Själv&shy;klart! För att ge er flex&shy;i&shy;bil&shy;itet och möj&shy;lighet att själva hantera vissa delar av er hem&shy;sida, kan vi skapa en använd&shy;ar&shy;vänlig funktion som låter er göra upp&shy;dater&shy;ingar. Efter&shy;som ni har valt oss för att skapa en unik design och en skräddar&shy;sydd mall som repre&shy;senterar ert före&shy;tag på bästa sätt, före&shy;slår vi att vissa funk&shy;tioner hålls låsta för att säker&shy;ställa en enhet&shy;lig användar&shy;upp&shy;levelse och bevara er varu&shy;märkes&shy;identitet.</p> <p>Vi är här för att stödja er, och om ni någonsin behöver genom&shy;föra större ändringar eller har tekniska frågor, är vi mer än villiga att hjälpa er. Er fram&shy;gång är vår prio&shy;ritet, och vi ser fram emot att fort&shy;sätta sam&shy;ar&shy;betet för att säker&shy;ställa att er hem&shy;sida är både professionell och lättanvänd.</p>'
    },
    {
        itemkey: 'QA9',
        Q: 'Kan ni hjälpa mig att skapa en mail?',
        A: '<p>Själv&shy;klart! Vi kan hjälpa dig att skapa en mail med en adress som passar ditt varumärke.</p>'
    },
    {
        itemkey: 'QA10',
        Q: 'Vem kan anställa Grafikmakeriet?',
        A: '<p>Grafikmakeriet erbjuder sina tjänster till både privat&shy;personer och före&shy;tag. Oavsett om du planerar en speciell händelse som ett bröllop eller behöver pro&shy;fession&shy;ella tryck&shy;saker för ditt före&shy;tag, är vårt dedi&shy;kerade team redo att skräddarsy lös&shy;ningar som passar dina behov. Vi väl&shy;komnar alla projekt, stora som små, och ser fram emot att hjälpa dig för&shy;verk&shy;liga dina visioner och mål. Kontakta oss för att disku&shy;tera hur vi kan bidra till att göra ditt projekt eller even&shy;emang minnesvärt och framgångsrikt.</p>'
    },
    {
        itemkey: 'QA11',
        Q: 'Kan ni ta hand om min hem&shy;sida?',
        A: '<p>Absolut! Vi är special&shy;ister inom hant&shy;ering och ut&shy;veck&shy;ling av webb&shy;platser. Med vår erfar&shy;enhet och dedika&shy;tion är vi redo att ta hand om din hem&shy;sida och se till att den inte bara upp&shy;fyller utan över&shy;träffar dina för&shy;vänt&shy;ningar. Låt oss disk&shy;utera dina speci&shy;fika behov och hur vi kan optimera och för&shy;bättra din webb&shy;plats för att säkerställa dess framgång online.</p>'
    }
]
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Colors, LinkColor } from "../../styles/Colors";
import { Sizes } from "../../styles/Sizes";
import { Wrapper } from "../styling-components/Wrapper";
import { SH } from "../styling-components/TEXT/SmallHeading";

import IconsContainer, { IconContainer } from "../lego-static-parts/SoMeIconsContainer";
import { SoMeButton } from "../styling-components/ICON-Styles/SoMeButton";

import UnderFooter from "./UnderFooter";
import LogoLDW, { LogoBox } from "../lego-static-parts/LOGO/LogoLDW";

const FooterBox = styled.footer`
  height: auto;
  padding-top: calc(${Sizes.BaseUnit}*2);
  padding-bottom: ${Sizes.BaseUnit};
  margin-top: ${Sizes.ComponentMargin};

  background-color: ${Colors.Blue};

  p,
  ${SH} {
    color: ${Colors.White};
  }
`;

const FooterWrapper = styled(Wrapper)`
  @media only screen and (min-width: 860px) {
    display: flex;
    justify-content: space-between;

    #foot1 {
      max-width: 40%;
    }

    #foot2,
    #foot3 {
      width: auto;
    }
  }

  @media only screen and (min-width: 626px) and (max-width: 859px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: max-content repeat(1, 1fr);

    grid-row-gap: calc(${Sizes.BaseUnit} * 2);

    text-align: center;
    width: 75vw;

    #foot1 {
      grid-area: 1 / 1 / 2 / 9;
    }

    #foot2 {
      grid-area: 2 / 2 / 3 / 5;
    }

    #foot3 {
      grid-area: 2 / 5 / 3 / 8;
    }
  }

  @media only screen and (min-width: 555px) and (max-width: 625px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: max-content repeat(1, 1fr);

    grid-row-gap: calc(${Sizes.BaseUnit} * 2);

    text-align: center;
    width: 85vw;
    margin-bottom: calc(${Sizes.BaseUnit} * 1.5);

    #foot1 {
      grid-area: 1 / 1 / 2 / 9;
    }

    #foot2 {
      grid-area: 2 / 2 / 3 / 5;
    }

    #foot3 {
      grid-area: 2 / 5 / 3 / 8;
    }
  }

  @media only screen and (min-width: 520px) and (max-width: 554px) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: max-content repeat(1, 1fr);

    grid-row-gap: calc(${Sizes.BaseUnit} * 2);

    text-align: center;
    width: 90vw;

    #foot1 {
      grid-area: 1 / 1 / 2 / 9;
    }

    #foot2 {
      grid-area: 2 / 2 / 3 / 5;
    }

    #foot3 {
      grid-area: 2 / 5 / 3 / 8;
    }
  }

  @media only screen and (max-width: 519px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: ${Sizes.BaseUnit};
    width: 90vw;

    #foot1 {
      order: 1;
    }

    #foot2 {
      order: 3;
    }

    #foot3 {
      order: 2;
    }
  }
`;

const InfoBox = styled.div`
  @media only screen and (min-width: 860px) {
    max-width: 40%;
  }

  ${LogoBox} {
    height: 2.5rem;
  }

  p {
    font-weight: 200;
    margin-top: ${Sizes.IconGap};
  }

  @media only screen and (max-width: 519px) {
    ${LogoBox} {
      height: 3rem;
    }

    p {
      font-size: 17px;
      font-weight: 300;
    }
  }
`;

const LinkColumn = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12em;

    color: ${Colors.White};

    &:hover {
      color: ${LinkColor.LinkHoverDarkBG};
    }

    &:active {
      color: ${LinkColor.LinkActiveDarkBG};
    }
  }

  a ~ a {
    margin-top: ${Sizes.LinkSpacing};
  }

  ${SH} {
    margin-bottom: 0.3rem;
  }

  ${IconContainer} {
    opacity: 90%;

    @media only screen and (min-width: 859px) {
      margin-left: -0.5rem;
      margin-top: 1rem;
    }

    @media only screen and (max-width: 858px) {
      margin: ${Sizes.BaseUnit} auto 0;
    }

    ${SoMeButton} {
      a {
        svg {
          fill: ${LinkColor.LinkDarkBG};
          opacity: 95%;
        }

        svg:hover {
          fill: ${LinkColor.LinkHoverDarkBG};
        }

        svg:active {
          fill: ${LinkColor.LinkActiveDarkBG};
        }
      }
    }
  }

  @media only screen and (min-width: 860px) {
    width: auto;
  }

  @media only screen and (max-width: 519px) {
    margin-top: calc(${Sizes.BaseUnit} * 1.7);

    ${SH} {
      font-size: 16px;
    }

    a {
      font-size: 15px;
      line-height: 1.05rem;
    }
  }
`;

export default function Footer() {
  return (
    <FooterBox>
      <FooterWrapper>
        <InfoBox id="foot1">
          <LogoLDW />

          <p>
            Varje före&shy;tag har en unik story att berätta. Grafikmakeriet är
            din lokala design&shy;byrå som hjälper ditt före&shy;tag att hitta
            sin unika röst och kreativa ut&shy;tryck för att kommunicera er
            story på ett sätt som engagerar och inspirerar.
          </p>
        </InfoBox>

        <LinkColumn id="foot2">
          <Link to="/Services">
            <SH>Tjänster</SH>
          </Link>
          <Link to="/GraphicProfile">Grafisk Profil</Link>
          <Link to="/Print">Tryck</Link>
          <Link to="/Strategy">Strategi</Link>
          <Link to="/Webb">Webb</Link>

          <IconsContainer />
        </LinkColumn>

        <LinkColumn id="foot3">
          <SH>Support & information</SH>
          <Link to="/About">Om Grafikmakeriet</Link>
          <Link to="/HowTo">Hur funkar det?</Link>
          <Link to="/FAQ">Frågor & svar</Link>
          <Link to="/Offert">Offert</Link>
          <Link to="/Prices">Priser</Link>
          <Link to="/Contact">Kontakt</Link>
        </LinkColumn>
      </FooterWrapper>

      <UnderFooter />
    </FooterBox>
  );
}
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { HowToItems, MenuLinks, ServiceItems } from '../../content-info/MenuLinks'
import { Colors } from '../../styles/Colors'
import { Sizes } from '../../styles/Sizes'

import { Wrapper } from '../styling-components/Wrapper'
import { SH } from '../styling-components/TEXT/SmallHeading'

import Dropdown, { DropdownBox } from '../dynamic-parts/Dropdown'

const LinkContainer = styled.div`
  height: ${ Sizes.NavHeight };
  display: flex;
  align-items: center;
  position: relative;

  > a {
    height: ${ Sizes.NavHeight };
    display: flex;
    align-items: center;
  }

  ${ SH } {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: .75rem;
      left: -4%;

      width: 108%;
      height: .0625rem;
    
    /*  Fixa så underlinen alltid syns när dropdown är aktiverad!:
          openHowTo
          openAbout
          transform: $ {({ openServices }) => openServices ? 'scaleX(1)' : 'scaleX(0)'};
    */

      background-color: ${ Colors.BlackOpacity };
      transform: scaleX(0);
      transition: opacity .35s cubic-bezier(.55,.03,.37,.96), transform .35s cubic-bezier(.55,.03,.37,.96);
    }

    &:hover::after {
      transform: scaleX(1);
    }      
  }

  ${ DropdownBox } {
    position: absolute;
    top: calc( ${ Sizes.NavHeight } - 0.0625rem - 1px);
    left: 50%;
    transform: translateX(-50%);
  }
`

const NavBox = styled.nav`
  z-index: 10000;
  height: ${ Sizes.NavHeight };
  margin-bottom: calc( ${ Sizes.BaseUnit } *2 );
  border-bottom: 1px solid ${ Colors.DropdownBorder };

  ${ Wrapper } {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: calc( ${ Sizes.Wrapper } *0.6 );
  }

  @media only screen and (max-width: 767px) {
     display: none;
  }
`

export default function Nav() {
  //Services-Dropdown
  const [openServices, setOpenServices] = useState(false);
  const handleOpenServices = () => setOpenServices(!openServices);

  //HowTo-Dropdown
  const [openHowTo, setOpenHowTo] = useState(false);
  const handleOpenHowTo = () => setOpenHowTo(!openHowTo);

  return (
    <NavBox>
      <Wrapper>
        <LinkContainer openServices = { openServices } onMouseEnter={() => setOpenServices(true)} onMouseLeave={() => setOpenServices(false)}>
          <SH>{ MenuLinks[0].title }</SH>

          {openServices ? <Dropdown items={ ServiceItems } onItemClick={ handleOpenServices }/> : <></>}
        </LinkContainer>

        <LinkContainer openHowTo={ openHowTo } onMouseEnter={() => setOpenHowTo(true)} onMouseLeave={() => setOpenHowTo(false)} onClick={() => setOpenHowTo(false)}>
          <Link to='HowTo'>
            <SH>{ MenuLinks[1].title }</SH>
          </Link>

          {openHowTo ? <Dropdown items={ HowToItems } onItemClick={ handleOpenHowTo }/> : <></>}
        </LinkContainer>

        <LinkContainer>
          <Link to='About'>
            <SH>{ MenuLinks[2].title }</SH>
          </Link>
        </LinkContainer>

        <LinkContainer>
          <Link to={'Contact'}>
            <SH>Kontakt</SH>
          </Link>
        </LinkContainer>
      </Wrapper>
    </NavBox>
  )
}




/* Aktivera sidor för: Service, Hur funkar det & Om oss */
/* Orginal-kod:

  //About-Dropdown
  const [openAbout, setOpenAbout] = useState(false);
  const handleOpenAbout = () => setOpenAbout(!openAbout);

  <LinkContainer openServices = { openServices } onMouseEnter={() => setOpenServices(true)} onMouseLeave={() => setOpenServices(false)} onClick={() => setOpenServices(false)}>
      <Link to='Services'>
        <SH>{ MenuLinks[0].title }</SH>
      </Link>

    {openServices ? <Dropdown items={ ServiceItems } onItemClick={ handleOpenServices }/> : <></>}
  </LinkContainer>

  <LinkContainer openHowTo={ openHowTo } onMouseEnter={() => setOpenHowTo(true)} onMouseLeave={() => setOpenHowTo(false)} onClick={() => setOpenHowTo(false)}>
    <Link to='HowTo'>
      <SH>{ MenuLinks[1].title }</SH>
    </Link>

    {openHowTo ? <Dropdown items={ HowToItems } onItemClick={ handleOpenHowTo }/> : <></>}
  </LinkContainer>

  <LinkContainer openAbout={ openAbout } onMouseEnter={() => setOpenAbout(true)} onMouseLeave={() => setOpenAbout(false)} onClick={() => setOpenAbout(false)}>
    <Link to='About'>
      <SH>{ MenuLinks[2].title }</SH>
    </Link>

    {openAbout ? <Dropdown items={ AboutItems } onItemClick={ handleOpenAbout }/> : <></>}
  </LinkContainer>
*/
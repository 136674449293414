import React from "react";
import styled from "styled-components";
import { MobileColors } from "../../../styles/Colors";
import { InnerCircle, OuterCircle } from "./BigTimer";

const UseButton = styled.button`
  height: 32px;
  width: auto;
  padding: 0 18px;
  border-radius: 15px;
  //'#00bc9b', '#743ad5', '#d53a9d', '#5eaefd';
  border: 1.5px solid transparent;
  background: linear-gradient(${MobileColors.ObjectBG}, ${MobileColors.ObjectBG}) padding-box, linear-gradient(to bottom right, #743ad5, #d53a9d) border-box;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: ${MobileColors.TextColor};

  :active {
    box-shadow: inset ${MobileColors.DarkShadow},
      inset ${MobileColors.LightShadow};
  }
`;

const HoverLay = styled.div`
z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  backdrop-filter: blur(2px);
  transition: all 0.5s;
`;

export const TimerTime = styled.div`
  font-family: -apple-system, lato, new-atten, brandon-grotesque,
    mr-eaves-modern, Outfit, apparat-light, "Open Sans", sans-serif;
  font-size: 20px;
  line-height: 2.55rem;
  font-weight: 500;
  letter-spacing: 0.3pt;
  color: ${MobileColors.TextColor};
`;

const SmallTimerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 144px;
  height: 144px;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;

  box-shadow: ${MobileColors.DarkShadow}, ${MobileColors.LightShadow};

  background: linear-gradient(
        ${MobileColors.ObjectBG},
        ${MobileColors.ObjectBG}
      )
      padding-box,
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0)) border-box;
  border: 0.25px solid transparent;

  ${InnerCircle} {
    box-shadow: ${MobileColors.InsetDarkShadow2},
      ${MobileColors.InsetLightShadow};
  }

  :hover {
    ${HoverLay} {
      opacity: 1;
    }
  }
`;

export default function SmallTimer({setUsed, hkey, h, m, s }) {
 console.log(h.toString().padStart(2, "0"))
  return (
    <SmallTimerBox>
      <OuterCircle>
        <InnerCircle>
          <TimerTime key={hkey}>
            {h.toString().padStart(2, "0")}:{m.toString().padStart(2, "0")}:
            {s.toString().padStart(2, "0")}
          </TimerTime>
        </InnerCircle>
      </OuterCircle>
      <HoverLay>
        <UseButton onClick={() => setUsed(h, m, s)}>Use</UseButton>
      </HoverLay>
    </SmallTimerBox>
  );
}

/** onClick={changeBigTimer()} 
 * 
 * h, m, s, hkey, setUsed, 
 * 
 * 
{Object.keys(timerValue).map((item, index) => {
          return (
            <TimerTime key={index}>
              {timerValue[index].timeHMS.h + ":" + timerValue[index].timeHMS.m + ":" + timerValue[index].timeHMS.s + "---"}
            </TimerTime>
          );
        })}
      
 *  return (
    <SmallTimerBox>
      {timerValue.map((item, index) => {
        return (
          <TimerTime key={index + item.timerKey}>
            {item.timeHMS.map((props, index) => {
              return (
                <FlexTime>
                  <div key={index + "h"}>{props.timeHMS.h}</div>:
                  <div key={index + "m"}>{props.timeHMS.m}</div>:
                  <div key={index + "s"}>{props.timeHMS.s}</div>
                </FlexTime>
              );
            })}
          </TimerTime>
        );
      })}
 * 
 *  {items && items.map((item, index) => {
      if(item.path) {
        return (
          <Link key={ index + item.timerKey>
              { item.title }
              <LinkHoverLine/>
          </Link>
        );
      }
      else if(item.subItems) {
        return (
          <SH key={ index + item.id }>{ item.title }</SH>
            {item.subItems.map((sub, index) => {
              return(
                  <Link key={ index + item.timerKey }>
                      { sub.title }
                  </Link>
              );
            }
          </SH>
        )
      }
    })}
*/

// {timeItems.map(( timeItem, index ) => {
//   return (
//     <TimerTime key={timeItem.timerKey[index]} >
//       { timeItem[index].time.h }:{ timeItem[index].time.m }:{ timeItem[index].time.s }
//     </TimerTime>
//   );
// })}

/**
 *       {timeItems.map(( timeItem ) => {
        return (
          <TimerTime key={timeItem.timerKey}>
            {timeItem.time.h}:{timeItem.time.m}:{timeItem.time.s}
          </TimerTime>
        );
      })}
 */

import { AiOutlineYoutube } from "react-icons/ai";
import { SoMeButton } from "../../styling-components/ICON-Styles/SoMeButton";

export default function YouTubeIcon() {
  return (
    <SoMeButton>
      <a href="https://youtube.com/@Grafikmakeriet?si=HL59OcMF4Ns-TRDq" target="_blank" rel="noreferrer">
        <AiOutlineYoutube />
      </a>
    </SoMeButton>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MenuLinks, ServiceItems, HowToItems } from "../../content-info/MenuLinks";
import { Colors, LinkColor } from "../../styles/Colors";
import { Sizes } from "../../styles/Sizes";

import { SH } from "../styling-components/TEXT/SmallHeading";
import IconsContainer, { IconContainer } from "../lego-static-parts/SoMeIconsContainer";
import { ButtonDBG } from "./buttons/Button";
import { MenuLine } from "./lines/MenuLine";
import { LogoOGmenu, LogoOG } from "../lego-static-parts/LOGO/LogoOG";

export const MenuList = styled.div`
  z-index: 1100;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  align-items: center;
  text-align: center;

  padding: calc(${Sizes.NavHeight} * 0.8) calc(${Sizes.BaseUnit} * 0.5) 0;
`;

const MenuSection = styled.div`
  flex: 1;

  ${LogoOG} {
    height: auto;
    width: 100%;
    margin-bottom: calc(${Sizes.BaseUnit} * 1.4);

    a {
      display: flex;
    }
  }

  ${SH} {
    font-size: 1.095rem;
    color: ${LinkColor.LinkDarkBG};
  }
`;

export const ListSection = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.13em;
    line-height: 1.38rem;

    color: ${LinkColor.LinkDarkBG};

    &:hover {
      color: ${LinkColor.LinkHoverDarkBG};
    }

    &:active {
      color: ${LinkColor.LinkActiveDarkBG};
    }
  }

  & ~ ${MenuLine} {
    margin: calc(${Sizes.BaseUnit} * 1.4) 0;
  }
`;

const ContactText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  line-height: 1.2rem;
  color: ${Colors.White};
  margin: 0 auto calc(${Sizes.IconGap} * 1.7);
  max-width: 78vw;
`;

const ContactSection = styled.div`
  z-index: 9500;
  width: 100vw;
  height: auto;
  bottom: 0;

  background-color: ${Colors.Blue};
  padding: calc(${Sizes.BaseUnit} * 1.5) calc(${Sizes.BaseUnit} * 1.7)
    calc(${Sizes.BaseUnit} * 1.6);

  border-top: 0.03rem solid ${Colors.WhiteOpacity};
  margin-top: calc(${Sizes.BaseUnit} * 4);

  ${ButtonDBG} {
    width: auto;
    padding: 0 calc(${Sizes.BaseUnit} * 1.8);
  }

  ${MenuLine} {
    flex: 0;
    margin: calc(${Sizes.BaseUnit} * 1.9) 0 calc(${Sizes.IconGap} * 1.5);
  }

  ${IconContainer} {
    a {
      svg {
        fill: ${LinkColor.LinkDarkBG};
        opacity: 95%;
      }

      svg:hover {
        fill: ${LinkColor.LinkHoverDarkBG};
      }

      svg:active {
        fill: ${LinkColor.LinkActiveDarkBG};
      }
    }
  }
`;

export default function HamburgerList() {
  return (
    <MenuList data-testid="HamburgerList">
      <MenuSection>
        <LogoOGmenu />

        <ListSection>
          <SH>{MenuLinks[0].title}</SH>

          {ServiceItems.map((item) => (
            <Link key={item.itemkey} to={item.path}>
              {item.title}
            </Link>
          ))}
        </ListSection>

        <MenuLine />

        <ListSection>
          <Link to={MenuLinks[1].path}>
            <SH>{MenuLinks[1].title}</SH>
          </Link>

          {HowToItems.map((item) => (
            <Link key={item.itemkey} to={item.path}>
              {item.title}
            </Link>
          ))}
        </ListSection>

        <MenuLine />

        <ListSection>
          <Link to="/About">
            <SH>Om Grafikmakeriet</SH>
          </Link>
        </ListSection>
      </MenuSection>

      <ContactSection>
        <ContactText>
          Vill du veta mer om hur vi kan jobba ihop? Eller diskutera en idé du
          har över en kaffe?{" "}
        </ContactText>
        <Link to={"/Contact"}>
          <ButtonDBG>Kontakta oss</ButtonDBG>
        </Link>

        <MenuLine />

        <IconsContainer />
      </ContactSection>
    </MenuList>
  );
}

/** Lägg till:
 *      - Sök-rad
 *      - Login-knapp
 */

import styled from "styled-components";
import { Colors } from "../../../styles/Colors";

export const MenuLine = styled.div`
    display: inline-block;
    width: 78vw;
    height: .035rem;

    background-color: ${ Colors.White };
    opacity: 70%;
`
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { InputBox } from '../styling-components/FORM/FormInput'
import { FormContainer } from '../styling-components/FORM/FormContainer'
import { Sizes } from '../../styles/Sizes'
import { Colors } from '../../styles/Colors'
import { ButtonDBG } from '../lego-static-parts/buttons/Button'

const LogInBox = styled.div`
    @media only screen and (min-width: 1601px) {
        width: 20vw;
    }

    @media only screen and (min-width: 1250px)and (max-width: 1600px) {
        width: 25vw;
    }

    @media only screen and (min-width: 1030px) and (max-width: 1249px) {
        width: 30vw;
    }

    @media only screen and (min-width: 890px) and (max-width: 1029px) {
        width: 35vw;
    }

    @media only screen and (min-width: 768px) and (max-width: 889px) {
        width: 40vw;
    }

    @media only screen and (min-width: 500px) and (max-width: 767px) {
        width: 45vw;
        margin-top: calc( ${ Sizes.BaseUnit } *2);
    }

    @media only screen and (max-width: 499px) {
        width: 85vw;
        margin-top: calc( ${ Sizes.BaseUnit } *2);
    }

    height: auto;
    margin: 0 auto;
    padding: calc( ${ Sizes.BaseUnit } *3) calc( ${ Sizes.BaseUnit } *2) calc( ${ Sizes.BaseUnit } *5);

    text-align: center;

    border: 1px solid ${ Colors.GreyText };
    border-radius: calc( ${ Sizes.BorderRadius } *2);

    ${ InputBox } + ${ InputBox } {
        margin-top: ${ Sizes.IconGap };
        margin-bottom: calc( ${ Sizes.IconGap } *2);
    }

    ${ ButtonDBG } {
        width: 50%;
        margin-bottom: ${ Sizes.BaseUnit };
    }

    a {
        color: ${ Colors.VivaMagenta };
        text-decoration: underline;
        text-underline-offset: .2rem;
        text-decoration-thickness: 0.02rem;
    }

    a, p {
        font-family: quasimoda, new-atten, brandon-grotesque, mr-eaves-modern, apparat-light, proxima-nova, montserrat, lato, -apple-system, 'Open Sans', sans-serif;
        font-weight: 400;
    }
`

export default function LogInForm() {
  return (
    <LogInBox>
        <h2>Logga in</h2>

        <FormContainer>
            <InputBox>
                <input type='email' id='user' placeholder='Mail*' required/>
                <label htmlFor="user">Mail*</label>
            </InputBox>

            <InputBox>
                <input type='password' id='password' placeholder='Password' required/>
                <label htmlFor="user">Password</label>
            </InputBox>

            <ButtonDBG type='submit' id='UserLogIn' name='LogIn'>Logga in</ButtonDBG>
        </FormContainer>


        <p>Har du inget konto? <Link to={'/Contact'}>Kontakta oss!</Link></p>
    </LogInBox>
  )
}

import React from "react";
import styled from "styled-components";
import { Wrapper } from "../components/styling-components/Wrapper";
import ContactForm, { ContactBox } from "../components/main/ContactForm";
import { ContactItem } from "../content-info/ContactH2H4";
import Collapsable from "../components/dynamic-parts/Collapsable";
import { Sizes } from "../styles/Sizes";
import { QAitems } from "../content-info/QA";

const FAQBox = styled.div`
  ${ContactBox} {
    margin-top: calc(${Sizes.BaseUnit} * 4);
  }
`;

export default function FAQ() {
  return (
    <FAQBox>
      <Wrapper>
        {QAitems.map((item) => {
          return <Collapsable item={item} />;
        })}

        <ContactForm h2item={ContactItem[6].h2} h4item={ContactItem[6].h4} />
      </Wrapper>
    </FAQBox>
  );
}
import React from 'react'
import { Wrapper } from '../components/styling-components/Wrapper'
import LogInForm from '../components/main/LogInForm'


export default function LogIn() {
  return (
    <Wrapper>
      <LogInForm/>
    </Wrapper>
  )
}
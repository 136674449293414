import { AiOutlineInstagram } from "react-icons/ai";
import { SoMeButton } from "../../styling-components/ICON-Styles/SoMeButton";

export default function InstagramIcon() {
  return (
    <SoMeButton>
      <a href="https://www.instagram.com/grafikmakeriet/" target="_blank" rel="noreferrer">
        <AiOutlineInstagram />
      </a>
    </SoMeButton>
  );
}

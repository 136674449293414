import styled from "styled-components";
import { Wrapper } from "../components/styling-components/Wrapper";
import { Sizes } from "../styles/Sizes";
import { ContactItem } from "../content-info/ContactH2H4";

import { SH } from "../components/styling-components/TEXT/SmallHeading";
import ContactForm, { ContactBox } from "../components/main/ContactForm";

const HowToBox = styled.div`
  ol {
    margin-top: calc(${Sizes.BaseUnit} * 2);
  }

  li::marker {
    font-family: new-atten, apparat, quire-sans, proxima-nova, brandon-grotesque,
      quasimoda, montserrat, lato, -apple-system, "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 1.1rem;
  }

  p {
    margin-bottom: ${Sizes.BaseUnit};
  }

  ${ContactBox} {
    margin-top: calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (min-width: 870px) {
    width: calc(${Sizes.Wrapper} * 0.5);
    margin: 0 auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (min-width: 768px) and (max-width: 869px) {
    max-width: 70vw;
    margin: 0 auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    max-width: 70vw;
    margin: calc(${Sizes.BaseUnit} * 2) auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (max-width: 600px) {
    max-width: 85vw;
    margin: calc(${Sizes.BaseUnit} * 2) auto calc(${Sizes.BaseUnit} * 3);
  }
`;

export default function HowTo() {
  return (
    <Wrapper>
      <HowToBox>
        <h2>Hur funkar det?</h2>
        <p>
          Vi tror på att skapa en personlig och engagerad upp&shy;levelse för varje
          kund. Vår arbets&shy;process är ut&shy;formad för att vara trans&shy;parent och
          sam&shy;arbetande, vilket gör det möjligt för oss att för&shy;vandla dina idéer
          till impo&shy;ner&shy;ande verk&shy;lighet. Här är en översikt över hur vi tar oss an
          varje projekt:
        </p>

        <ol>
          <li>
            <SH>Kontakt och Intro&shy;duktion:</SH>
            <p>
              Vi inleder processen genom att ta kontakt och lära känna dina
              behov och mål. Detta är vår första chans att förstå din vision och
              hur vi bäst kan stödja dig.
            </p>
          </li>

          <li>
            <SH>Projekt&shy;möte:</SH>
            <p>
              Efter vår in&shy;led&shy;ande kontakt bokar vi in ett möte för att för&shy;djupa
              vår för&shy;stå&shy;else för projektet. Vi disk&shy;uterar dina specifika
              önske&shy;mål, mål och event&shy;uella dead&shy;lines.
            </p>
          </li>

          <li>
            <SH>Skissning och Koncept&shy;ualis&shy;ering:</SH>
            <p>
              Beroende på projektets om&shy;fatt&shy;ning skapar vi antingen en in&shy;led&shy;nde
              skiss eller ett detalj&shy;erat koncept. Detta ger dig en första glimt
              av hur vi tänker visual&shy;isera din idé.
            </p>
          </li>

          <li>
            <SH>Första utkast:</SH>
            <p>
              Vi tar fram det första ut&shy;kastet bas&shy;erat på våra diskus&shy;sioner och
              skisser. Detta ut&shy;kast utgör grunden för vidare disk&shy;ussion och
              just&shy;er&shy;ingar.
            </p>
          </li>

          <li>
            <SH>Feed&shy;back och Korrig&shy;eringar:</SH>
            <p>
              Du granskar det första ut&shy;kastet och ger feed&shy;back. Vi ink&shy;luderar
              två korriger&shy;ings&shy;rundor i vårt standard&shy;paket för att säker&shy;ställa
              att designen är i linje med dina för&shy;vänt&shy;ningar.
            </p>
          </li>

          <li>
            <SH>Design&shy;final&shy;isering:</SH>
            <p>
              När det första ut&shy;kastet är god&shy;känt, går vi vidare med att fin&shy;slipa
              och färdig&shy;ställa designen enligt dina önske&shy;mål.
            </p>
          </li>

          <li>
            <SH>God&shy;kännande och Leverans:</SH>
            <p>
              Du får möjlighet att god&shy;känna den slut&shy;giltiga designen innan den
              leve&shy;reras. Om ytter&shy;ligare korriger&shy;ings&shy;rundor önskas till&shy;kommer en
              extra kostnad per runda.
            </p>
          </li>

          <li>
            <SH>Slut&shy;förande och Upp&shy;följ&shy;ning:</SH>
            <p>
              Efter att designen har leve&shy;rerats håller vi kontakt för att
              säker&shy;ställa att du är helt nöjd och att alla dina behov har
              upp&shy;fyllts.
            </p>
          </li>
        </ol>

        <p>
          Vi strävar efter att göra varje steg i pro&shy;cessen trans&shy;parent och
          samar&shy;beta tätt med dig för att skapa resultat som över&shy;träffar dina
          för&shy;vänt&shy;ningar.
        </p>

        <ContactForm h2item={ContactItem[5].h2} h4item={ContactItem[5].h4} />
      </HowToBox>
    </Wrapper>
  );
}

//import React from 'react'

export default function SetUsedTimer(h, m, s) {
    console.log(h);
   // setHour(h);
   //setMinute(m);
   //setSecond(s);

   <p>
    Swat around the house steal the warm chair right after you get up
              but knock dish off table. Head butt. Cant eat out of my own dish i
              can haz for has closed eyes but still sees. You my slave hooman.
              Cat sit like bread. Get suspicious of own shadow then go play with
              toilette paper. Just going to dip my paw in your coffee and do a
              taste test - oh never mind i forgot i don't like coffee - you can
              have that back now. Fight an alligator and win. Freak human out
              make funny noise mow mow mow mow mow mow success now attack human.
              
Ge din närvaro på nätet en boost!
Vår expertis sträcker sig över webbutveckling, från modern design till kraftfull funktionalitet. Oavsett om du behöver en ny webbplats, en uppdatering av den befintliga eller support med andra webbutvecklingsprojekt, är vi redo att hjälpa dig. Med vår skräddarsydda approach tar vi oss gärna an ditt projekt inom webb- och applikationsutveckling. Med fokus på att leverera högkvalitativa lösningar kan du känna dig trygg med att ditt digitala projekt är i goda händer.
    
Vi tar oss gärna an ditt projekt inom webb- och applikationsutveckling och ser till att leverera högkvalitativa lösningar som passar just dina behov.
   </p>
}

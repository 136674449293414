import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import Main from './layouts/Main';
import Home from './pages/Home';
import Services from './pages/Services/Services';
  import GraphicProfile from './pages/Services/GraphicProfile';
  import Print from './pages/Services/Print';
  import Strategy from './pages/Services/Strategy';
  import Webb from './pages/Services/Webb';

import Contact from './pages/Contact';
import About from './pages/About';
import HowTo from './pages/HowTo';
import FAQ from './pages/FAQ';
import Offert from './pages/Offert';
import Prices from './pages/Prices';

import LogIn from './pages/LogIn';

import Privacy from './pages/static-pages/Privacy';
import Cookies from './pages/static-pages/Cookies';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Main/>}>
      <Route index element={<Home/>}/>
      <Route path='Services' element={<Services/>}/>
        <Route path='GraphicProfile' element={<GraphicProfile/>}/>
        <Route path='Print' element={<Print/>}/>
        <Route path='Strategy' element={<Strategy/>}/>
        <Route path='Webb' element={<Webb/>}/>

      <Route path='Contact' element={<Contact/>}/>
      <Route path='About' element={<About/>}/>
      <Route path='HowTo' element={<HowTo/>}/>
      <Route path='FAQ' element={<FAQ/>}/>
      <Route path='Offert' element={<Offert/>}/>
      <Route path='Prices' element={<Prices/>}/>

      <Route path='LogIn' element={<LogIn/>}/>

      <Route path='Privacy' element={<Privacy/>}/>
      <Route path='Cookies' element={<Cookies/>}/>
    </Route>
  )
)


function App() {
  return (
    <RouterProvider router={ router }/>
  );
}

export default App;

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, LinkColor } from '../../styles/Colors'
import { Sizes } from '../../styles/Sizes'
import { LinkHoverLine } from '../lego-static-parts/lines/LinkHoverLine'


export const DropdownBox = styled.div`
    z-index: 50;
    
    display: flex;
    flex-direction: column;

    height: auto;
    min-width: 270px;

    position: absolute;

    padding: calc( ${ Sizes.BaseUnit } *1.3 ) calc( ${ Sizes.BaseUnit } *1.3 )  calc( ${ Sizes.BaseUnit } *2 );


    background-color: ${ Colors.DropDown };
    border: 1px solid ${ Colors.DropdownBorder };

    text-align: center;

    a {
        width: 100%;

        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .06em;
        line-height: 1.7rem;

        color: ${ LinkColor.LinkLightBG };

        &:hover {
            color: ${ LinkColor.LinkHoverLightBG };

            ${ LinkHoverLine } {
                display: block;
                position: absolute;
                width: calc(270px - calc( ${ Sizes.BaseUnit } *6 ));

                margin-top: -0.21rem;
                left: 50%;
                transform: translateX(-50%);

                background-color: ${ LinkColor.LinkHoverLightBG };
            }
        }

        &:active {
            color: ${ LinkColor.LinkActiveLightBG };

            ${ LinkHoverLine } {
                background-color: ${ LinkColor.LinkActiveLightBG };
            }
        }
    }
`

export default function Dropdown({ items, onItemClick }) {
  return (
    <DropdownBox>
        {items.map(( item ) => {
            return(
                <Link key={ item.itemkey } to={ item.path } onClick={ onItemClick }>
                    { item.title }
                    <LinkHoverLine/>
                </Link>
            );
        })}
    </DropdownBox>
  )
}

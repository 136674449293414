import React from "react";
import styled from "styled-components";
import { Sizes } from "../styles/Sizes";

import { Wrapper } from "../components/styling-components/Wrapper";
import { Adorn } from "../components/styling-components/TEXT/Adorn";

import SiljanImg from "../images/Siljan-RGB.jpg";
import Founder from "../components/main/Founder";

const AboutBox = styled(Wrapper)`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
`;

const ImgContainer = styled.div`
  max-width: 45%;
`;

const Siljan = styled.img`
  z-index: -100;
  max-width: 45vw;
  pointer-events: none;
  position: absolute;
`;

const Big = styled.p`
  margin-bottom: calc(${Sizes.ParagraphSpacing} * 0.8);
`;

const AboutContainer = styled.div`
  max-width: 39%;
  margin-top: calc(${Sizes.BaseUnit} * 2);
  margin-bottom: calc(${Sizes.BaseUnit} * 10);

  ${Big} {
    font-weight: 600;
  }

  p {
    font-weight: 300;
  }

  p + h2 {
    margin-top: calc(${Sizes.BaseUnit} * 1.5);
  }
`;

const HeadingContainer = styled.div`
  ${Adorn} {
    font-size: 4.7em;
    line-height: 3.8rem;
    font-weight: 400;
    margin-bottom: 0;

    @media only screen and (max-width: 994px) {
      display: none;
    }
  }

  h3 {
    font-size: 1.16rem;
    font-weight: 400;
  }

  margin-bottom: calc(${Sizes.ParagraphSpacing} * 1.5);
`;

const GMh2 = styled.h3`
  font-family: "GM-Script";
  font-size: 5.5rem;
  line-height: 4rem;
  font-weight: 400;
  margin-bottom: 0;

  @media only screen and (min-width: 995px) {
    display: none;
  }

  @media only screen and (max-width: 994px) {
    display: block;
  }
`;

const NAh2 = styled.h2`
  font-size: 1.15rem;
  line-height: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.15rem;

  @media only screen and (min-width: 995px) {
    display: none;
  }

  @media only screen and (max-width: 994px) {
    display: block;
  }
`;

const AboutWrapper = styled.div`
  @media only screen and (min-width: ${Sizes.Wrapper}) and (max-width: 1437px) {
    ${Adorn} {
      font-size: 3.9rem;
    }
  }

  @media only screen and (min-width: 995px) and (max-width: ${Sizes.Wrapper}) {
    ${AboutBox} {
      width: 85vw;
      padding: 0;
    }

    ${AboutContainer} {
      text-align: center;
      max-width: 43%;
      margin-bottom: calc(${Sizes.BaseUnit} * 5);
    }

    ${Adorn} {
      font-size: 4rem;
      line-height: 2.5rem;
      margin-bottom: ${Sizes.ParagraphSpacing};
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 994px) {
    ${AboutBox} {
      width: 85vw;
      padding: 0;
    }

    ${AboutContainer} {
      text-align: center;
      max-width: 45%;
      margin-bottom: calc(${Sizes.BaseUnit} * 5);
    }

    ${GMh2} {
      font-size: 5rem;
    }
  }

  @media only screen and (min-width: 734px) and (max-width: 767px) {
    ${AboutBox} {
      width: 92vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 150vw;
      top: 3%;
      left: -8%;
    }

    ${AboutContainer} {
      text-align: center;
      max-width: 48vw;
      margin: calc(${Sizes.BaseUnit} * 0.5) 0 calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 5rem;
    }
  }

  @media only screen and (min-width: 676px) and (max-width: 733px) {
    ${AboutBox} {
      width: 92vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 185vw;
      top: 3%;
      left: -10%;
    }

    ${AboutContainer} {
      text-align: center;
      max-width: 48vw;
      margin: calc(${Sizes.BaseUnit} * 1) 0 calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 5.5rem;
    }
  }

  @media only screen and (min-width: 631px) and (max-width: 675px) {
    ${AboutBox} {
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 200vw;
      top: -1%;
      left: -17%;
    }

    ${AboutContainer} {
      max-width: 53vw;
      margin: calc(${Sizes.BaseUnit} * 0.5) 0 calc(${Sizes.BaseUnit} * 6) auto;
    }

    ${GMh2} {
      font-size: 5rem;
    }
  }

  @media only screen and (min-width: 596px) and (max-width: 630px) {
    ${AboutBox} {
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 220vw;
      top: 1%;
      left: -25%;
    }

    ${AboutContainer} {
      max-width: 53vw;
      margin: calc(${Sizes.BaseUnit} * 0.5) 0 calc(${Sizes.BaseUnit} * 6) auto;
    }

    ${GMh2} {
      font-size: 5rem;
    }
  }

  @media only screen and (min-width: 550px) and (max-width: 595px) {
    ${AboutBox} {
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 250vw;
      top: 1%;
      left: -35%;
    }

    ${AboutContainer} {
      max-width: 53vw;
      margin: 0 0 calc(${Sizes.BaseUnit} * 6) auto;
    }

    ${GMh2} {
      font-size: 5.4rem;
    }
  }

  @media only screen and (min-width: 480px) and (max-width: 549px) {
    ${AboutBox} {
      display: block;
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 150vw;
      top: 40%;
      left: -37%;
    }

    ${AboutContainer} {
      max-width: 70vw;
      margin: calc(${Sizes.BaseUnit} * 0.7) auto calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 6rem;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 480px) {
    ${AboutBox} {
      display: block;
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 160vw;
      top: 40%;
      left: -40%;
    }

    ${AboutContainer} {
      max-width: 75vw;
      margin: calc(${Sizes.BaseUnit} * 0.7) auto calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 6rem;
    }
  }

  @media only screen and (min-width: 401px) and (max-width: 450px) {
    ${AboutBox} {
      display: block;
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 170vw;
      top: 55%;
      left: -43%;
    }

    ${AboutContainer} {
      max-width: 80vw;
      margin: calc(${Sizes.BaseUnit} * 0.5) auto calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 6rem;
    }

    p {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 400px) {
    ${AboutBox} {
      display: block;
      text-align: center;
      width: 90vw;
      margin: 0 auto;
      padding: 0;
    }

    ${Siljan} {
      max-width: 170vw;
      top: 65%;
      left: -43%;
    }

    ${AboutContainer} {
      max-width: 85vw;
      margin: calc(${Sizes.BaseUnit} * 0.5) auto calc(${Sizes.BaseUnit} * 6);
    }

    ${GMh2} {
      font-size: 5rem;
    }

    p {
      font-size: 18px;
    }
  }
`;

export default function About() {
  return (
    <AboutWrapper>
      <AboutBox>
        <ImgContainer>
          <Siljan src={SiljanImg} />
        </ImgContainer>

        <AboutContainer>
          <HeadingContainer>
            <Adorn>Välkommen till Grafikmakeriet,</Adorn>
            <NAh2>Välkommen till </NAh2>
            <GMh2>Grafikmakeriet,</GMh2>

            <h3>
              <em>- din lokala mediapartner i hjärtat av Mora, Dalarna.</em>
            </h3>
          </HeadingContainer>

          <Big>
            Hos oss strävar vi efter att vara mer än bara en grafisk designbyrå
            – vi är din dedikerade följeslagare i varje steg på resan mot ett
            starkt och minnesvärt varumärke.
          </Big>

          <p>
            Vi tror på kraften av kreativitet, design och strategi för att skapa
            en unik identitet som sticker ut. Genom skräddarsydda lösningar inom
            design, webbutveckling och marknadsföring arbetar vi hand i hand med
            dig för att förverkliga din vision och sätta din verksamhet på
            kartan.
          </p>

          <h2>Bakom grafikmakeriet</h2>
          <p>
            Inom vårt dedikerade team finner du Linnéa Mäker, grundare och
            kreativ ledare bakom Grafikmakeriet och när projektet kräver det så
            har vi förmånen att genom ett brett kontaktnät kunna plocka in
            specialister och konsulter inom olika områden där det behövs. Denna
            flexibilitet gör att vi kan erbjuda skräddarsydda lösningar och
            garantera att varje projekt hanteras med expertis och precision.
          </p>
        </AboutContainer>
      </AboutBox>

      <Founder/>
    </AboutWrapper>
  );
}

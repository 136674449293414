import { Link } from 'react-router-dom';
import styled from "styled-components";

import { ReactComponent as LogoLieDownWhite } from '../../../images/GM_Symbol_Liggande_White.svg';
import { Colors } from '../../../styles/Colors';

export const LogoBox = styled.div`
   // height: 2.5rem; ANGE I ANVÄNDNINGSFILEN!!
    width: auto;

    a {
      height: 100%;
    }
`

const LogoLDWhite = styled(LogoLieDownWhite)`
  height: 100%;
  width: auto;

  & path {
    fill: ${ Colors.DarkBG };
  }
`

export default function LogoLDW() {
  return (
    <LogoBox>
        <Link to='/'>
            <LogoLDWhite/>
        </Link>
    </LogoBox>
  )
}
import React from "react";
import styled from "styled-components";

import FaceBookIcon from "../icons/SoMe/FaceBook";
import InstagramIcon from "../icons/SoMe/Instagram";
import YouTubeIcon from "../icons/SoMe/YouTubeIcon";
/* import TikTokIcon from "../icons/SoMe/TikTokIcon";*/

export const IconContainer = styled.div``;

export default function IconsContainer() {
  return (
    <IconContainer>
      <FaceBookIcon />
      <InstagramIcon />
      <YouTubeIcon />
    </IconContainer>
  );
}

/* <TikTokIcon /> */
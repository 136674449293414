import React from "react";
import { Wrapper } from "../../components/styling-components/Wrapper";
import { TextContainer } from "../../components/styling-components/CONTAINERS/TextContainer";

export default function Privacy() {
  return (
    <Wrapper>
      <TextContainer>
        <h2>Integritetspolicy</h2>
        <p>
          Vi arbetar för att säker&shy;ställa en hög nivå av data&shy;skydd som
          värnar om din person&shy;liga inte&shy;gritet vid använd&shy;ning av
          vår webb&shy;plats. Denna integritets&shy;policy för&shy;klarar
          där&shy;för hur dina person&shy;upp&shy;gifter bear&shy;betas och
          skyddas, dina rättig&shy;heter och hur du kan göra dem gäll&shy;ande.
          Vi strävar efter att du ska känna dig trygg i vår be&shy;hand&shy;ling
          av dina person&shy;uppgifter. Vid event&shy;uella frågor är du
          väl&shy;kommen att kontakta oss. Våra kontakt&shy;upp&shy;gifter
          hittar du längst ned på denna sida.
        </p>

        <h3>Vem ansvarar för de person&shy;uppgifter vi samlar in?</h3>
        <p>
          Grafikmakeriet - Dal&shy;arna, org. Nr 920907-XXXX, Fridhems&shy;plan
          2D, 792 30 Mora, är person&shy;upp&shy;gifts&shy;an&shy;svarig för de
          person&shy;upp&shy;gifter du lämnar till oss genom köp och övrig
          kontakt med oss och an&shy;svarar för be&shy;hand&shy;ling av dessa.
        </p>

        <h3>Var lagras dina person&shy;uppgifter?</h3>
        <p>
          Vi efter&shy;strävar att dina person&shy;upp&shy;gifter i så hög grad
          som möjligt ska behand&shy;las inom det euro&shy;peiska
          ekonom&shy;iska samar&shy;bets&shy;området (EES) och alla våra egna
          IT-&shy;system, men kan även över&shy;föras och bear&shy;betas i ett
          land utanför EES (s.k. ”tredje land”). Sådan över&shy;föring sker
          endast till mot&shy;tagar&shy;land med adekvat skydds&shy;nivå och i
          enlig&shy;het med gällande lag&shy;stift&shy;ning. Om
          över&shy;för&shy;ing sker till part&shy;ner för en tjänst i USA sker
          detta endast till företag som om&shy;fattas av det så kallade ”Privacy
          Shield”, vilken är en överens&shy;kommelse om skydd för
          person&shy;upp&shy;gifter mellan EU och USA.
        </p>

        <h3>Vad för slags person&shy;upp&shy;gifter samlar vi in?</h3>
        <p>
          Vi samlar in person&shy;upp&shy;gifter som du skickar till oss när du
          gör beställ&shy;ningar på vår webb&shy;plats, anmäler dig till vårt
          nyhets&shy;brev eller deltar i event&shy;uella tävlingar. De
          person&shy;upp&shy;gifter som du skickar till oss kan till exempel
          omfatta kontakt&shy;inform&shy;ation, födelse&shy;datum och
          betalnings&shy;inform&shy;ation. Vi kan också komma att samla in vissa
          person&shy;upp&shy;gifter från externa part&shy;ners såsom
          adress&shy;upp&shy;gifter från offent&shy;liga regi&shy;ster och
          upp&shy;gifter om kredit&shy;värdighet från
          kredit&shy;värderings&shy;institut, banker eller
          kredit&shy;upp&shy;lysnings&shy;företag.
        </p>

        <h3>Hur använder vi dina person&shy;uppgifter?</h3>
        <p>
          Vi kan komma att använda dina person&shy;uppgifter för följ&shy;ande
          ända&shy;mål:
          <ul>
            <li>
              Vid bearbet&shy;ning av beställ&shy;ningar och returer i vår
              webb&shy;shop.
            </li>
            <li>Vid sms-&shy;aviser&shy;ingar om leverans&shy;status.</li>
            <li>
              Vid kontakter angående event&shy;uella problem med din leverans.
            </li>
            <li>
              Vid korres&shy;pondens om frågor och övrig inform&shy;ation.
            </li>
            <li>
              Vid ut&shy;skick om erbjud&shy;anden i form av nyhets&shy;brev, då
              alltid med instrukt&shy;ioner om hur du kan avanmäla dig ifrån
              vidare ut&shy;skick.{" "}
            </li>
            <li>
              Vid analyser av köp&shy;mönster för att ge rele&shy;vanta
              erbjud&shy;anden och inform&shy;tion.
            </li>
            <li>
              Vid bekräft&shy;else av minimi&shy;ålder som enligt lag gäller för
              att få handla online.
            </li>
            <li>
              Vid ut&shy;annons&shy;ering av vinnare i tävlingar som vi
              arrang&shy;erar.
            </li>
            <li>
              Vid event&shy;uella enkät&shy;ut&shy;skick för att förbättra våra
              tjänster och erbjud&shy;anden.
            </li>
            <li>
              Vid arbete som för&shy;hindrar miss&shy;bruk eller annan olämplig
              använd&shy;ning av vår hem&shy;sida.
            </li>
          </ul>
          Vi sparar dina upp&shy;gifter så länge som det krävs för att
          upp&shy;fylla ovan&shy;stående ända&shy;mål eller så länge som vi
          enligt lag är skyld&shy;iga att göra detta. Där&shy;efter raderas dina
          person&shy;uppgifter.
        </p>

        <h3>Vilka är dina rätt&shy;igheter?</h3>
        <p>
          Du har rätt att när som helst begära inform&shy;ation om de
          person&shy;uppgifter vi har om dig. Om dina upp&shy;gifter är
          fel&shy;aktiga, ofull&shy;ständiga eller irrele&shy;vanta, kan du
          begära att få dem rättade eller rader&shy;ade. Vi kan inte radera dina
          upp&shy;gifter då det före&shy;ligger ett lag&shy;stadgat krav på
          lagring, som exempel&shy;vis bok&shy;förings&shy;regler, eller när det
          finns andra legitima skäl till varför upp&shy;gift&shy;erna måste
          sparas, till exempel obetalda fakturor. Du kan när som helst
          åter&shy;kalla ditt sam&shy;tycke till att låta oss använda
          upp&shy;gifterna för marknads&shy;föring genom att av&shy;an&shy;mäla
          dig från vårt nyhets&shy;brev. Du kan också kontakta oss genom att
          skicka brev eller e-&shy;post. Se kontakt&shy;uppgifter nedan.
        </p>

        <h3>Vilka kan vi komma att dela dina person&shy;uppgifter med?</h3>
        <p>
          För att vi ska kunna erbjuda ovan&shy;nämnda tjänster och våra
          åtag&shy;anden gente&shy;mot dig som kund delar vi dina
          person&shy;uppgifter med före&shy;tag som är s.k.
          person&shy;uppgifts&shy;biträden för oss.
          Person&shy;uppgifts&shy;biträden behandlar inform&shy;ationen för vår
          räk&shy;ning och enligt våra instruk&shy;tioner och hjälper oss med
          IT-&shy;tjänster, betal&shy;tjänster, trans&shy;porter och
          marknads&shy;föring. Detta sker endast för ända&shy;mål för vilka vi
          har samlat inform&shy;ationen och endast till före&shy;tag som kan
          lämna till&shy;räck&shy;liga garan&shy;tier gäll&shy;ande
          säker&shy;het och sekre&shy;tess för person&shy;upp&shy;gifter.
        </p>
        <p>
          Vi vidare&shy;be&shy;for&shy;drar, säljer eller byter aldrig dina
          person&shy;upp&shy;gifter för marknads&shy;förings&shy;ända&shy;mål
          till tredje part.
        </p>

        <h3>Hur skyddar vi dina person&shy;upp&shy;gifter?</h3>
        <p>
          För att dina person&shy;upp&shy;gifter som skickas till vår
          webb&shy;plats inte ska läsas av utom&shy;stående, krypt&shy;eras all
          sådan inform&shy;ation och skickas via en säker anslut&shy;ning.
        </p>
      </TextContainer>
    </Wrapper>
  );
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../components/main/Header';
import Nav from '../components/main/Nav';
import Footer from '../components/main/Footer';


const MainLayout = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  overflow: hidden;
`

const MainWrapper = styled.div`
  flex: 1;
`

export default function Main() {
  return (
    <MainLayout>
        <Header/>
        <Nav/>

        <MainWrapper>
            <Outlet/>
        </MainWrapper>

        <Footer/>
    </MainLayout>
  )
}

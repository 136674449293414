import React from 'react'
import styled from 'styled-components'
import { Sizes } from '../styles/Sizes'
import { ContactItem } from '../content-info/ContactH2H4'

import { Wrapper } from '../components/styling-components/Wrapper'
import ContactForm from '../components/main/ContactForm'

const PricesBox = styled.div`
  h4 {
    margin-bottom: calc( ${ Sizes.BaseUnit } *2);
  }
`

export default function Prices() {
  return (
    <PricesBox>
      <Wrapper>
        <ContactForm h2item={ ContactItem[8].h2 } h4item={ ContactItem[8].h4 }/>
      </Wrapper>
    </PricesBox>
  )
}

/**
 *  “If you think it’s expensive to hire a professional to do the job, wait until you hire an amateur.” — Red Adair, Oil Well Firefighter
 *
 *  “Have no fear of perfection — you’ll never reach it.” — Salvador Dali
 **/
import styled from "styled-components";
import { Wrapper } from "../../components/styling-components/Wrapper";
import ContactForm from "../../components/main/ContactForm";
import { ContactItem } from "../../content-info/ContactH2H4";
import { Sizes } from "../../styles/Sizes";

const StrategyBox = styled.div`
  h4 {
    margin-bottom: calc( ${ Sizes.BaseUnit } *2);
  }
`;

export default function Strategy() {
  return (
    <StrategyBox>
      <Wrapper>
        <ContactForm h2item={ContactItem[3].h2} h4item={ContactItem[3].h4} />
      </Wrapper>
    </StrategyBox>
  );
}


/**
 * “Every great design begins with an even better story.” — Lorinda Mamo
 **/

import styled from "styled-components";
import { Wrapper } from "../../components/styling-components/Wrapper";
import ContactForm from "../../components/main/ContactForm";
import { ContactItem } from "../../content-info/ContactH2H4";
import { Sizes } from "../../styles/Sizes";
import MobileDeviceDisplayComponent from "../../components/main/webbPage/MobileDeviceComponent";

const WebbBox = styled.div`
  h4 {
    margin-bottom: calc(${Sizes.BaseUnit} * 2);
  }
`;

export default function Webb() {
  return (
    <WebbBox>
      <MobileDeviceDisplayComponent />
      <Wrapper>
        <ContactForm h2item={ContactItem[4].h2} h4item={ContactItem[4].h4} />
      </Wrapper>
    </WebbBox>
  );
}

/**
  Webb
  Varför webb hos Grafikmakeriet? 
  För att 

  Är du också trött på mallsidor som ser likadana ut som andra sidor? Hos oss får du en skräddarsydd kodad hemsida från scratch med våra egna lösningar.
 */

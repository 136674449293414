import React from "react";
import styled from "styled-components";

import LowerAllMask from "../../../../images/FrontEndComponentLowerMaskBG2.0.png";

export const LowerBGMask = styled.svg`
  position: absolute;
  bottom: 0;

  width: 2048px;
  height: 1014.7px;
  background: url(${LowerAllMask});
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: inset(1px 0);
`;

export default function LowerMask(scrollPosition) {
  return <LowerBGMask scrollPosition={scrollPosition} />;
}

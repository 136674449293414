import styled from 'styled-components'

import { Sizes } from '../../styles/Sizes';

import { Wrapper } from '../styling-components/Wrapper';
import { Flex } from '../styling-components/CONTAINERS/Flex';
/* import User from '../icons/User';
import SearchBar from '../lego-static-parts/SearchBar'; */
import HamburgerMenu, { HamburgerMenuBox } from '../lego-static-parts/HamburgerMenu';
import HeaderLogo, { HeaderLogoBox, LogoLieDownBlack } from '../lego-static-parts/LOGO/LogoHeader';
import { Colors } from '../../styles/Colors';

const SearchUserContainer = styled(Flex)``

const HeaderBox = styled.header`
  z-index: 3000;
  position: relative;
  border-bottom: 1px solid ${ Colors.DropdownBorder };

  @media only screen and (max-width: 767px) {
    margin-bottom: calc( ${ Sizes.BaseUnit } *2 );
  }

  ${Wrapper} {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding-top: calc( ${ Sizes.BaseUnit } *0.75);
    padding-bottom: calc( ${ Sizes.BaseUnit } *0.75);

    @media only screen and (min-width: 768px) {
      height: calc( ${ Sizes.NavHeight } *1.5 );

      ${ HeaderLogoBox } {
        height: calc( ${ Sizes.NavHeight } *1.5 );
        max-height: 100%;

        margin: 0 auto;

        ${ LogoLieDownBlack } {
          height: ${ Sizes.NavHeight };
        }
      }

      ${ SearchUserContainer } {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-25%, -50%); 
      }

      ${ HamburgerMenuBox } {
        display: none;
      }
    }

    @media only screen and (max-width: 767px) {
      height: calc( ${ Sizes.NavHeight } *2 );

      ${ HeaderLogoBox } {
        height: calc( ${ Sizes.NavHeight } *2 );
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        ${ LogoLieDownBlack } {
          height: calc( ${ Sizes.NavHeight } *2 );
        }
      }

      ${ SearchUserContainer } {
        display: none;
      }

      ${ HamburgerMenuBox } {
        z-index: 10000;
        position: ${({ isComponentVisible }) => isComponentVisible ? 'fixed' : ''};

        height: calc( ${ Sizes.NavHeight } *2 );
        width: ${({ isComponentVisible }) => isComponentVisible ? 'auto' : '1.6rem'};
      }
    }
  }
`

export default function Header() {
  return (
    <HeaderBox>
      <Wrapper data-testid='HeaderWrapper'>
        <HeaderLogo/>


        <HamburgerMenu/>
      </Wrapper>
    </HeaderBox>
  )
}

/** Att göra:
 *    - Sticky on Scroll
 *    - Logo mot symbol on scroll
 */

/*         <SearchUserContainer>
          <SearchBar/>
          <User/>
        </SearchUserContainer>
 */
import React from 'react'
import styled from 'styled-components';

import Hero from '../components/main/Hero';
import BrandMission from '../components/main/BrandMission';
import ContactForm, { ContactBox } from '../components/main/ContactForm';
import { Sizes } from '../styles/Sizes';
import { ContactItem } from '../content-info/ContactH2H4';


const HomeMain = styled.div`
    z-index: 20;

    ${ ContactBox }  {
        @media only screen and (min-width: 859px) { 
            margin-top: calc(${ Sizes.BaseUnit } *7);
            margin-bottom: calc(${ Sizes.BaseUnit } *5);
        }

        @media only screen and (min-width: 550px) and (max-width: 858px) { 
            margin-top: calc(${ Sizes.BaseUnit } *5);
            margin-bottom: calc(${ Sizes.BaseUnit } *4);
        }

        @media only screen and (max-width: 549px) { 
            margin-top: calc(${ Sizes.BaseUnit } *4);
            margin-bottom: calc(${ Sizes.BaseUnit } *2);
        }
    }
`

export default function Home() {
    return (
        <HomeMain>
            <Hero/>

            <BrandMission/>

            <ContactForm h2item={ContactItem[0].h2} h4item={ContactItem[0].h4} />
        </HomeMain>
    )
}
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AiFillBell } from "react-icons/ai";
import {
  IoMdSettings,
  IoIosPlay,
  IoIosPause,
  IoMdRefresh,
} from "react-icons/io";
import { IoStop } from "react-icons/io5";
import { MobileColors } from "../../../styles/Colors";
import CountdownRange from "./CountdownRange";

const BigTimerBox = styled.div`
  z-index: 5000;
  height: auto;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  background: linear-gradient(
        ${MobileColors.ObjectBG},
        ${MobileColors.ObjectBG}
      )
      padding-box,
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0)) border-box;
  border: 0.25px solid transparent;

  box-shadow: ${MobileColors.DarkShadow}, ${MobileColors.LightShadow};
`;

export const OuterCircle = styled.div`
  width: 90%;
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 8px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  background: linear-gradient(
        ${MobileColors.ObjectBG},
        ${MobileColors.ObjectBG}
      )
      padding-box,
    linear-gradient(to bottom right, #fff, rgba(160, 160, 160, 0.12)) border-box;
  border: 1px solid transparent;

  box-shadow: ${MobileColors.OuterOuterDarkShadow},
    ${MobileColors.InsetLightShadow}, inset ${MobileColors.DarkShadow},
    inset ${MobileColors.LightShadow};

  font-family: -apple-system, lato, new-atten, brandon-grotesque,
    mr-eaves-modern, Outfit, apparat-light, "Open Sans", sans-serif;
`;

export const InnerCircle = styled.div`
  z-index: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80%;
  width: 80%;
  border-radius: 50%;
  overflow: hidden;

  background: linear-gradient(
        ${MobileColors.ObjectBG},
        ${MobileColors.ObjectBG}
      )
      padding-box,
    linear-gradient(to bottom right, #fff, rgba(160, 160, 160, 0.18)) border-box;
  border: 1px solid transparent;

  box-shadow: ${MobileColors.InsetDarkShadow2}, ${MobileColors.InsetLightShadow};
`;

const HoursDiv = styled.select`
  // z-index: 2000;
`;
const MinutesDiv = styled.select`
  // z-index: 2100;
`;
const SecondsDiv = styled.select`
  // z-index: 2200;
`;

const TimeViewer = styled.div`
  display: flex;
  align-items: center;
  height: 55%;
  width: auto;
  position: relative;
  margin: auto;
  //overflow: hidden; //Prova vad "auto" gör

  &&,
  ${HoursDiv}, ${MinutesDiv}, ${SecondsDiv} {
    font-size: 43px;
    line-height: 2.55rem;
    font-weight: 500;
    letter-spacing: 0.3pt;
    color: ${MobileColors.TextColor};
  }

  select {
    appearance: none;
    border: none;
    background-color: transparent;
    display: inline-block;
  }

  /* &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: auto;
    pointer-events: none;
  }

  &:before {
    content: "00:15:01";
    top: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(242, 242, 242, 1) 100%,
      rgba(242, 242, 242, 0)
    );
  }

  &:after {
    content: "00:15:02";
    bottom: 0;
    background-image: linear-gradient(
      to top,
      rgba(242, 242, 242, 1) 60%,
      rgba(242, 242, 242, 0.8)
    );
  } */
`;

const BellBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;

  svg {
    transform: scale(1);
    height: 1rem;
    width: 1rem;

    fill: ${MobileColors.TextColor};
    margin-right: 6px;
  }
`;

const FinishTime = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);

  &&,
  em {
    color: ${MobileColors.TextColor};
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.7pt;
  }
`;

const SettingResetButton = styled.button`
  //z-index: 2300;
  height: 28px;
  width: 28px;

  svg {
    height: 0.9rem;
    width: 0.9rem;
  }
`;

const StartStopButton = styled.button`
  // z-index: 2400;
  height: 44px;
  width: 44px;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  // font-size: 14px;
  // font-weight: 500;
  // text-transform: uppercase;
  // letter-spacing: 0.7pt;
`;

const StartStopButtonBox = styled.div`
  //  z-index: 2500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: 10px auto 14px;

  button {
    border-radius: 15px;
    border: 1.5px solid rgba(160, 160, 160, 0.5);
    background-color: ${MobileColors.ObjectBG};

    :active {
      box-shadow: inset ${MobileColors.DarkShadow},
        inset ${MobileColors.LightShadow};
    }

    svg {
      display: flex;
      align-items: center;
      margin: auto;
      transform: scale(1);
      fill: ${MobileColors.TextColor};
    }
  }
`;

export default function BigTimer() {
  //- Time-variables
  const [endTime, setEndTime] = useState("--:--");
  const [calUpDEndTimesum, setCalUpDEndTimesum] = useState(0);
  const [sumInputToSeconds, setSumInputToSeconds] = useState(0);

  // Bryt ut dessa med tillhörande logik till en customHook
  const [hours, setHour] = useState(0);
  const [minutes, setMinute] = useState(0);
  const [seconds, setSecond] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  //- Stored Time => Stores initial Timer Setting for handleStop() 1st count
  const [storedHours, setStoredHours] = useState(0);
  const [storedMinutes, setStoredMinutes] = useState(0);
  const [storedSeconds, setStoredSeconds] = useState(0);
  const [storedSumInputToSeconds, setStoredSumInputToSeconds] = useState(0);

  //- Toggle Play/Pause
  const [playPause, setPlayPause] = useState(true);

  /** ------------------- Timer ------------------- */
  /** -------------- TimeRange ------------- */
  const [progress, setProgress] = useState(0);
  const [countdownRange, setCountdownRange] = useState(0);

  const circleWidth = 200;
  const circleStrokeWidth = 20;
  const center = circleWidth / 2;
  const rc = center - circleStrokeWidth / 2;
  const circumference = Math.PI * rc * 2; // 565.4866776461628
  const offset = circumference / progress;
  // const [calcOffset, setCalcOffset] = useState(0);

  let offsetCount = offset;

  // Detta bör vara en funktion som hör till ovan nämnda customhook
  // UseEffecten anser jag inte behövs
  useEffect(() => {
    let timer;

    if (timerRunning) {
      timer = setInterval(() => {
        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(timer);
          setTimerRunning(false);
          setPlayPause(true);
          setEndTime("--:--");
        } else {
          if (seconds === 0) {
            if (minutes === 0) {
              setHour((prevHours) => prevHours - 1);
              setMinute(59);
              setSecond(59);
            } else {
              setMinute((prevMinutes) => prevMinutes - 1);
              setSecond(59);
            }
          } else {
            setSecond((prevSeconds) => prevSeconds - 1);
          }

          setSumInputToSeconds((sumInputToSeconds * 100 / 100) - 1);
          setCountdownRange(countdownRange + offsetCount);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timerRunning, hours, minutes, seconds, sumInputToSeconds, countdownRange, offsetCount]);
  /** --------------------------------------------- */


  /** ----------------- TimeViewer ---------------- */

  //Samma sak här, en customHook som håller logiken för denna "komponenten" / delen av komponenten

  const updatedEndTimeOnChange = (hours, minutes, seconds) => {
    const now = new Date();
    const h = now.getHours();
    const m = now.getMinutes();
    const s = now.getSeconds();

    now.setHours(h + hours);
    now.setMinutes(m + minutes);
    now.setSeconds(s + seconds);

    const endHour = now.getHours();
    const endMinutes = now.getMinutes();
    const endSeconds = now.getSeconds();

    //const endSeconds = (endMinutes * 60) + now.getSeconds();
    //const calcEndMinutes = Math.floor(endSeconds / 60);

    if (hours === 0 && minutes === 0 && seconds === 0) {
      setEndTime("--:--");
    }

    //Sum endSeconds on count down:
    const calcH2M = endHour * 60;
    const calcM2S = (calcH2M + endMinutes) * 60;
    const calcSwS = calcM2S + endSeconds;
    setCalUpDEndTimesum(calcSwS); // Denna namnkonvention fattar jag inte

    //Sum seconds to count down:
    const sumHtM = hours * 60;
    const sumMtS = (sumHtM + minutes) * 60;
    setSumInputToSeconds(sumMtS + seconds);
    setCountdownRange(0);
  };

  const onHoursChange = (hours) => {
    setHour(hours);

    updatedEndTimeOnChange(hours, minutes, seconds);
  };

  const onMinutesChange = (minutes) => {
    setMinute(minutes);

    updatedEndTimeOnChange(hours, minutes, seconds);
  };

  const onSecondsChange = (seconds) => {
    setSecond(seconds);

    updatedEndTimeOnChange(hours, minutes, seconds);
  };

  /** --- Find present time, reCount presentTime => seconds & check if(endTime < nowTime){setEndTime to nowTime} --- */

  // Denna funktionalitet anser jag inte är MVP
  // Hade räck gott och väl imo att uppdatera sluttiden när användaren byter tid på timern

  //Detta bör vara en f

  useEffect(() => {
    const findPresentTime = setInterval(() => {
      const nowTime = new Date();
      const calcHoursToMinutes = nowTime.getHours() * 60;
      const calcMinutesToSeconds =
        (calcHoursToMinutes + nowTime.getMinutes()) * 60;
      const nowSecondsSum = calcMinutesToSeconds + nowTime.getSeconds();

      const hPt = nowTime.getHours();
      const mPt = nowTime.getMinutes();
      const sPt = nowTime.getSeconds();

      // 4 endTime === nowTime + input value
      const updatedNowTime4newEndTime = new Date();
      updatedNowTime4newEndTime.setHours(hPt + hours);
      updatedNowTime4newEndTime.setMinutes(mPt + minutes);
      updatedNowTime4newEndTime.setSeconds(sPt + seconds);

      const newEndHour = updatedNowTime4newEndTime.getHours();
      const newEndMinute = updatedNowTime4newEndTime.getMinutes();

      if (
        (nowSecondsSum > calUpDEndTimesum && calUpDEndTimesum !== 0) ||
        nowSecondsSum + sumInputToSeconds < calUpDEndTimesum + sumInputToSeconds
      ) {
        setEndTime(
          newEndHour.toString().padStart(2, "0") +
            ":" +
            newEndMinute.toString().padStart(2, "0")
        );
      }
    }, 1000);

    return () => clearInterval(findPresentTime);
  }, [calUpDEndTimesum, hours, minutes, seconds, sumInputToSeconds]);
  /** -------------------------------------------- */
  /** ----------- START - STOP - RESET ----------- */
  const [playCount, setPlayCount] = useState(0);
  const [storeCount, setStoreCount] = useState(0);
  const [stopCount, setStopCount] = useState(0);

  //Denna är för stor, ut i egen customHook med tillhörande state och funktioner
  const handleStart = () => {
    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      setPlayPause(true);
      setPlayCount(0);
      setStoreCount(0);
      setPlayCount(0);
      setStoredSumInputToSeconds(0);
    } else if (hours > 0 || minutes > 0 || seconds > 0) {
      if (playPause) {
        setPlayPause(!playPause);
        setTimerRunning(!timerRunning);
        setPlayCount(1);
        setStopCount(0);
        setStoreCount(1);

      //  setCountdownRange(sumInputToSeconds);
       setProgress(sumInputToSeconds)
      }
      if (!playPause && playCount > 0) {
        setTimerRunning(false);
        setPlayPause(!playPause);
        setPlayCount(0);
        setProgress(progress);
      }
    }

    // = On start = store initial value -> On stop = Load initial value
    if (storeCount === 0) {
      setStoredHours(hours);
      setStoredMinutes(minutes);
      setStoredSeconds(seconds);
      setStoredSumInputToSeconds(sumInputToSeconds);
    }
  };

  const handleStop = () => {
    setTimerRunning(false);
    clearInterval(timerRunning);
    setPlayCount(0);

    //On stop => Clear number count (Back to initial)
    if (stopCount === 0 && (hours !== 0 || minutes !== 0 || seconds !== 0)) {
      setHour(storedHours);
      setMinute(storedMinutes);
      setSecond(storedSeconds);
      setStoreCount(0);
      setStopCount(1);
      setSumInputToSeconds(storedSumInputToSeconds);
      setCountdownRange(0);
    } else if (
      stopCount === 1 &&
      (hours === storedHours ||
        minutes === storedMinutes ||
        seconds === storedSeconds)
    ) {
      setHour(0);
      setMinute(0);
      setSecond(0);
      setStoredHours(0);
      setStoredMinutes(0);
      setStoredSeconds(0);

      setStopCount(0);
      setEndTime("--:--");
      setCountdownRange(0);
    }

    //Change to play if pause
    if (!playPause) {
      setPlayPause(!playPause);
    }
  };

  //Reset all values & counter to 00:00:00
  const handleRefresh = () => {
    setHour(0);
    setMinute(0);
    setSecond(0);
    setStoredHours(0);
    setStoredMinutes(0);
    setStoredSeconds(0);

    setPlayCount(0);
    setStopCount(0);
    setStoreCount(0);
    setEndTime("--:--");
    setCountdownRange(0);

    if (!playPause) {
      setPlayPause(!playPause);
    }
  };

  //____TODO____
  //TimeViewer-RangeScroll

  return (
    <BigTimerBox>
      <OuterCircle>
        <InnerCircle>
          <TimeViewer>
            <HoursDiv
              value={hours}
              onChange={(e) => onHoursChange(parseInt(e.target.value))}
            >
              {[...Array(24)].map((_, index) => (
                <option key={index} value={index}>
                  {index.toString().padStart(2, "0")}
                </option>
              ))}
            </HoursDiv>
            :
            <MinutesDiv
              value={minutes}
              onChange={(e) => onMinutesChange(parseInt(e.target.value))}
            >
              {[...Array(60)].map((_, index) => (
                <option key={index} value={index}>
                  {index.toString().padStart(2, "0")}
                </option>
              ))}
            </MinutesDiv>
            :
            <SecondsDiv
              value={seconds}
              onChange={(e) => onSecondsChange(parseInt(e.target.value))}
            >
              {[...Array(60)].map((_, index) => (
                <option key={index} value={index}>
                  {index.toString().padStart(2, "0")}
                </option>
              ))}
            </SecondsDiv>
          </TimeViewer>

          <FinishTime>
            <BellBox>
              <AiFillBell />
            </BellBox>

            {playCount === 2 ? <em> {endTime} </em> : endTime}
          </FinishTime>
        </InnerCircle>
        <CountdownRange
          circleWidth={circleWidth}
          circleStrokeWidth={circleStrokeWidth}
          center={center}
          rc={rc}
          circumference={circumference}
          offset={countdownRange}
        />
      </OuterCircle>

      <StartStopButtonBox>
        <SettingResetButton>
          <IoMdSettings />
        </SettingResetButton>

        <StartStopButton playPause={playPause} onClick={handleStart}>
          {!playPause ? <IoIosPause /> : <IoIosPlay />}
        </StartStopButton>

        <StartStopButton onClick={handleStop}>
          <IoStop />
        </StartStopButton>

        <SettingResetButton onClick={handleRefresh}>
          <IoMdRefresh />
        </SettingResetButton>
      </StartStopButtonBox>
    </BigTimerBox>
  );
}

// function HigherOrderComponent() {
//   return <BigTimer />;
// }
/**
  <TimeViewer>
    <HoursDiv options={hourOptions} value={{ value: hours, label: hours }} onChange={(selectedOption) => setHours(selectedOption.value)}/>
    :
    <MinutesDiv options={minuteOptions} value={{ value: minutes, label: minutes.toString().padStart(2, '0') }} onChange={(selectedOption) => setMinutes(selectedOption.value)}/>
    :
    <SecondsDiv options={secondOptions} value={{ value: seconds, label: seconds.toString().padStart(2, '0') }} onChange={(selectedOption) => setSeconds(selectedOption.value)}/>
  </TimeViewer>


  return (
    <BigTimerBox>
      <OuterCircle>
        <InnerCircle>
          <TimeViewer>
            <TimePicker format="hh:mm:ss" value={time} onChange={handleTimeChange}/>
          </TimeViewer>

          <FinishTime>
            <BellBox>
              <AiFillBell />
            </BellBox>
            {activeHour <= 9 ? "0" + activeHour : activeHour}
            :
            {activeMinute <= 9 ? "0" + activeMinute : activeMinute }
          </FinishTime>
        </InnerCircle>
      </OuterCircle>

      <StartStopButtonBox>
        <StartStopButton playPause={playPause} onClick={handleStart} disabled={timerRunning}>
          {playPause || count < 1 ? <IoIosPlay /> : <IoIosPause />}
        </StartStopButton>
        <StartStopButton onClick={handleStop}>
          <IoStop />
        </StartStopButton>
      </StartStopButtonBox>
    </BigTimerBox>
  );
}
*/

/**
 * <TimeViewer>
            <HoursDiv value={hours} >
              {hours}
            </HoursDiv>
            :
            <MinutesDiv>
              {minutes}
            </MinutesDiv>
            :
            <SecondsDiv>
              {seconds}
            </SecondsDiv>
          </TimeViewer>

 */

/**
 * const Countdown = styled.svg`
  position: absolute;
  top: 0;
  left: 0;

  // width: 278px;
  // height: 278px;
  //border-radius: 50%;
  //  background: linear-gradient(#da22ff, #9733ee);

  linearGradient {
    height: 160px;
    width: 160px;
  } 

  circle {
    // fill: none;
   //  stroke: 5px solid #000;// url(#CountdownGradient);
     stroke-linecap: round;
   }
 `;

 *  <Countdown>
      <defs>
        <linearGradient id="CountdownGradient">
          <stop offset={"0%"} stopColor="#da22ff" />
          <stop offset={"100%"} stopColor="#9733ee" />
        </linearGradient>
      </defs>

      <circle
        cx={70}
        cy={70}
        r={70}
        id="mm"
      />

      <use x="5" y="5" href="mm" fill="url(#CountdownGradient)"/>
    </Countdown>
 */
/* 
  select[multiple] {
    padding-right: 0;
  }

  option {
    background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
  } */

/* option {
    background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    padding: 10px;
  } */

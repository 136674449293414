import React from "react";
import styled from "styled-components";
import { Sizes } from "../../styles/Sizes";
/* import { Colors } from "../../styles/Colors"; */ /** LÄGG TILL OM: Bocken ska ha tillbaa sin ruta! */

const CheckLineWidth = '5px';
const CheckLineHeight = '15px';

const Check = styled.div`
  @keyframes check-animation {
    0% {
      transform: scaleX(0);
      opacity: 0;
    }
    30% {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  position: relative;
  left: calc(${CheckLineHeight} * 0.075);
  top: calc(${CheckLineHeight} * 0.75);

  height: ${CheckLineWidth};
  width: ${CheckLineHeight};
  border-radius: calc(${CheckLineHeight} * 0.25) calc(${CheckLineHeight} * 0.25) 0 calc(${CheckLineHeight} * 0.25);

  background-color: #24d600; /** GRÖN */

  transform-origin: 0% 0%;
  animation: check-animation 1.3s ease-in 0ms 1;

  @keyframes check-before-animation {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    30% {
      transform: scaleY(0);
    }
    45% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  ::before {
    content: "";
    position: absolute;

    bottom: calc(${CheckLineHeight} * 0.1);
    right: 0;

    width: ${CheckLineWidth};
    height: calc(${CheckLineHeight} * 1.75);

    transform-origin: 0% 100%;
    border-radius: inherit;
    background-color: inherit;
    animation: check-before-animation 1.3s ease-out 0ms 1;
  }
`;

export const CheckWrapper = styled.div`
  display: flex;

  width: calc(${CheckLineHeight} * 1.4);
  height: calc(${CheckLineHeight} * 1.4);

  margin-left: ${Sizes.IconGap};

  transform: rotate(45deg);
`;

export default function CheckMark() {
  return (
    <CheckWrapper>
      <Check />
    </CheckWrapper>
  );
}

    /*  
        const CheckLineWidth = '.3rem';
        const CheckLineHeight = '1.2rem';

        height: 8px;
        width: 25px;
        border-radius: 5px 5px 0 5px; 
        
        ::before {
            bottom: 5px;
            right: 0;
            width: 8px;
            height: 50px; 
        }
    */

    /*   Checkmark-ruta:
        ::before {
            content: "";
            position: absolute;
            width: inherit;
            height: inherit;

            transform: rotate(-45deg) translateY(-10%);
            border: 1.3px solid ${Colors.Grey};
            border-radius: calc(${Sizes.BorderRadius} * 0.5);
         }
    */

import React from "react";
import { SoMeButton } from "../../styling-components/ICON-Styles/SoMeButton";
import { AiOutlineFacebook } from "react-icons/ai";

export default function FaceBookIcon() {
  return (
    <SoMeButton>
      <a href="https://www.facebook.com/grafikmakeriet" target="_blank" rel="noreferrer">
        <AiOutlineFacebook />
      </a>
    </SoMeButton>
  );
}

import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as OGLogoW } from '../../../images/GM-OG-white_layered.svg';
import { ReactComponent as OGLogoWhiteMenu } from '../../../images/GM-OG-W-Menu.svg';
import { Colors } from "../../../styles/Colors";


export const LogoOG = styled.div`
  // height: ANGE I ANVÄNDNINGSFILEN OM DET BEHÖVS!! ;
  // width: ANGE I ANVÄNDNINGSFILEN OM DET BEHÖVS!! ;

  a {
    height: 100%;
  }
`

const OGLogoWhite = styled(OGLogoW)`
    height: 100%;
    width: auto;

    & path {
        fill: ${ Colors.DarkBG };
    }
`

export function LogoOGwhite () {
    return (
        <LogoOG>
            <Link to='/'>
                <OGLogoWhite/>
            </Link>
        </LogoOG>
    )
}


const LogoMenu = styled(OGLogoWhiteMenu)`
    height: 100%;
    width: 100%;

    & path {
        fill: ${ Colors.DarkBG };
        opacity: 90%;
    }
`

export function LogoOGmenu() {
  return (
    <LogoOG>
        <Link to='/'>
            <LogoMenu/>
        </Link>
    </LogoOG>
  )
}

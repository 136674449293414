import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '../../components/styling-components/Wrapper'

const ServicesBox = styled.div`

`

export default function Services() {
  return (
    <ServicesBox>
      <Wrapper>
        Services
      </Wrapper>
    </ServicesBox>
  )
}

import { CompanyInfo } from "./Company";

export const ServiceItems = [
  {
    itemkey: 'I1.1',
    title: 'Grafisk profil',
    path: '/GraphicProfile'
  },
  {
    itemkey: 'I1.2',
    title: 'Tryck',
    path: '/Print'
  },
  {
    itemkey: 'I1.3',
    title: 'Strategi',
    path: '/Strategy'
  },
  {
    itemkey: 'I1.4',
    title: 'Webb',
    path: '/Webb'
  }
]

export const HowToItems = [
  {
    itemkey: 'I2.1',
    title: 'Frågor & Svar',
    path: '/FAQ'
  },
  {
    itemkey: 'I2.2',
    title: 'Offert',
    path: '/Offert'
  },
  {
    itemkey: 'I2.3',
    title: 'Priser',
    path: '/Prices'
  }
]


export const AboutItems = [
  {
    itemkey: 'I3.1',
    title: `Om ${ CompanyInfo.name }`,
    path: '/About'
  },
  {
    itemkey: 'I3.2',
    title: 'Kontakt',
    path: '/Contact'
  }
]

export const UserItems = [
  {
    itemkey: 'I4.1',
    title: 'Logga in',
    path: '/'
  },
  {
    itemkey: 'I4.2',
    title: 'Ordrar',
    path: '/'
  }
];

export const MenuLinks = [
  {
    itemkey: 'I5.1',
    title: 'Tjänster',
    path: '/Services',
    subItems: ServiceItems
  },
  {
    itemkey: 'I5.2',
    title: 'Hur funkar det?',
    path: '/HowTo',
    subItems: HowToItems
  },
  {
    itemkey: 'I5.3',
    title: `Om oss`,
    path: '/About',
    subItems: AboutItems
  }
];


/** MenuLinks =
  {
    itemkey: 'I5.4',
    title: 'Mina sidor',
    path: '/',
    subItems: UserItems
  }
 */
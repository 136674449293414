export const Sizes = {
    BaseUnit: '1rem',

    Wrapper: '1200px',
    WrapperPadding: '20px',
    NavHeight: '3rem',

    HeroHeight: '800px',

    IconGap: '0.5rem',

    ComponentMargin: '5rem',
    
    LinkSpacing: '0.25rem',

    ParagraphSpacing: '0.5rem',
   
    BorderRadius: '.5rem'
}

export const Indent = {
    BulletIndent: '3rem',
    TextIndent: Sizes.ParagraphSpacing
}
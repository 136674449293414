import React from 'react'
import { Wrapper } from '../../components/styling-components/Wrapper'
import { TextContainer } from '../../components/styling-components/CONTAINERS/TextContainer'

export default function Cookies() {
  return (
    <Wrapper>
      <TextContainer>
        <h3>Cookies</h3>
        <p>
          En cookie (eller kaka) är en liten text&shy;fil som en webb&shy;plats sparar på besök&shy;arens dator. Cookies används på många webb&shy;platser för att ge en besök&shy;are till&shy;gång till olika funk&shy;tioner. Inform&shy;ationen i en cookie kan också användas för att följa en använd&shy;ares surf&shy;ande på webb&shy;platser som använder samma cookie.
        </p>

        <h3>Måste jag tillåta cookies?</h3>
        <p>
          Denna webb&shy;plats använder cookies för funk&shy;tion&shy;alitet som till exempel för att det ska gå att handla i butiken i din person&shy;liga webb&shy;shop när du loggat in. Om du block&shy;erar cookies kan du därför ej heller handla i butiken. Utan cookies kan du dock fort&shy;farande titta på sidor och art&shy;iklar i butiken, men vissa funk&shy;tioner kan bete sig oväntat.
        </p>
        <p>
          Om du vill handla i butiken men inte vill att några cookies ska sparas på din dator när du stänger din webb&shy;läsare så kan du besöka sidan i privat läge. Detta läge kallas olika saker bero&shy;ende på din webb&shy;läsare: "In&shy;kognito&shy;fönster" (Chrome), "Privat fönster" (Firefox) eller "InPrivate&shy;-fönster" (Microsoft Edge). Alla cookies som satts under besöket i privat läge raderas då ifrån din dator när du stänger din webb&shy;läsare.
        </p>

        <h3>Hur använder vi cookies?</h3>
        <p>
          Denna webb&shy;plats använder cookies för att hantera art&shy;iklar i varu&shy;korgen, in&shy;logg&shy;ning på "mina sidor" och för vissa funk&shy;tioner som endast ska visas en gång per besök&shy;are. Om du stänger din webb&shy;läsare och senare kommer tillbaka till hem&shy;sidan så sparas dina inställ&shy;ningar och art&shy;iklar i varu&shy;korgen med hjälp av cookies. Vi använder även cookies för att kunna anpassa och göra inform&shy;ation och marknads&shy;föring så rele&shy;vant som möjligt för dig.
        </p>
        <p>
          Inform&shy;ationen om in&shy;logg&shy;ning på "mina sidor" lagras i en så kallad sessions&shy;cookie och raderas där&shy;för när du stänger din webb&shy;läsare. Våra övriga cookies lagrar inte inform&shy;ation för att ident&shy;ifiera dig.
        </p>
        <p>
          Vi använder även cookies som statistik&shy;under&shy;lag, till exempel för att se vilka artiklar och sidor som samma besök&shy;are tittar på för att kunna erbjuda auto&shy;matiska produkt&shy;rekommend&shy;ationer, samt för att för&shy;bättra din upp&shy;levelse i hem&shy;sidans kommande upp&shy;dateringar.
        </p>

        <h3>Tredjeparts&shy;cookies</h3>
        <p>
          Vi använder Google Analy&shy;tics och Ads som också sätter cookies, detta för att få en bild av hur våra besök&shy;are använder webb&shy;platsen samt för vår marknads&shy;föring. Dessa cookies inne&shy;håller inte heller identi&shy;fierande inform&shy;ation om vem du som besök&shy;aren är.
        </p>

        <h3>Person&shy;uppgifts&shy;ansvarig</h3>
        <p>
          Grafikmakeriet - Dalarna<br/>
          Organisations&shy;nummer: 920907-XXXX<br/>
          Fridhems&shy;plan 2D<br/>
          792 30 Mora<br/>
          Sverige<br/>
        </p>
        <p>
          E-post: grafikmakeriet@hotmail.com
        </p>

        <br/>

        <p><em>
          Denna integritets&shy;policy upp&shy;dateras senast: 2023-11-20.
        </em></p>
      </TextContainer>
    </Wrapper>
  )
}

import styled from "styled-components";
import { Sizes } from "../../../styles/Sizes";
import { ButtonColor, Colors, FormColor } from "../../../styles/Colors";

export const FormContainer = styled.form`
    margin: calc( ${ Sizes.BaseUnit } *1.2) auto ;
    width: 100%;

    input {
        padding-left: ${ Sizes.IconGap };
        padding-right: ${ Sizes.IconGap };
    }

    input, textarea {
        border-radius: ${ Sizes.BorderRadius };
        border: solid 1px ${ FormColor.Border };

        ::placeholder {
            color: transparent;
        }
    }

    textarea {
        max-width: 100%;
        min-width: 100%;
        min-height: calc(${ Sizes.BaseUnit } *8);
        padding: ${ Sizes.BaseUnit } ${ Sizes.IconGap } ;
    }

    button {
        height: calc(${ Sizes.BaseUnit } *3);
        width: calc(${ Sizes.BaseUnit } *12);
        margin: calc( ${ Sizes.IconGap } *1.5) auto 0;
        padding: 0;

        background-color: ${ ButtonColor.DarkBG };
        border: none;
        border-radius: ${ Sizes.BorderRadius };

        color: ${ Colors.White };
        font-family: 'new-atten', 'apparat', 'quire-sans', 'proxima-nova', 'brandon-grotesque', 'quasimoda', 'montserrat', 'lato', '-apple-system', 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: .9rem;
        letter-spacing: .12rem;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            background-color: ${ ButtonColor.HoverDarkBG };
        }

        &:active {
            background-color: ${ ButtonColor.ActiveDarkBG };
        }
    }
`
import { createGlobalStyle } from "styled-components";
import { Colors } from "./Colors";
import { Indent, Sizes } from "./Sizes";

/* import GMScript from "../fonts/GM-Script.ttf"
 */
import { Line } from "../components/lego-static-parts/lines/Line";
import { SideLine } from "../components/lego-static-parts/lines/SideLine";
import { Divider } from "../components/lego-static-parts/lines/Divider";
import { SH } from "../components/styling-components/TEXT/SmallHeading";
import { Adorn } from "../components/styling-components/TEXT/Adorn";

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        //Färg
        color: ${Colors.Black}
    }

    html {
        min-height: 100vh;
    }

    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #root {
        height: 100%;
    }

    //Fonter
    ${Adorn}, h1, h2, h3, h4, p, ul, ol {
        color: ${Colors.Black};
    }

    h1, h2, h3, h4, ${SH} {
        font-family: new-atten, apparat, quire-sans, brandon-grotesque, quasimoda, lato, -apple-system, 'Open Sans', sans-serif; 
    }

    h2 {
        font-weight: 500;
    }

    h4 {
        font-weight: 400;
        font-size: 1.1rem;
    }

    p {
        font-family: quasimoda, Outfit, new-atten, brandon-grotesque, mr-eaves-modern, apparat-light, lato, -apple-system, 'Open Sans', sans-serif;
        font-weight: 200;
        hyphens: manual;
    }

    a {
        font-family: apparat-light, apparat, Lato, -apple-system, 'Open Sans', sans-serif;
        cursor: pointer;
        text-decoration: none;
        font-weight: 300;
    }

    p ~ p {
		margin-bottom: ${Sizes.ParagraphSpacing};
	}

/*     @font-face {
        font-family: 'GM-Script';
        font-weight: normal;
        font-style: normal;
        src: url({ GMScript });
    }
 */
    //Listor
    ul, ol {
        margin-bottom: ${Sizes.ParagraphSpacing};
        margin-left: ${Indent.BulletIndent};
        list-style-position: outside;
    }

    li {
        padding-left: ${Indent.TextIndent};
        padding-bottom: .2rem;
     }

    //Färger på Linje-componenter
    ${Line}, ${SideLine}, ${Divider}  {
        opacity: 30%;
    }
`;

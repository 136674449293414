import styled from "styled-components";
import { Sizes } from "../../../styles/Sizes";

/** Används när hela sidan är text. (Såsom Intigritetspolicy & Cookies) */

export const TextContainer = styled.div`
  padding: 0 ${Sizes.WrapperPadding};

  h3 {
    margin-top: calc(${Sizes.BaseUnit} * 1.5);
    margin-bottom: 0;
  }

  p {
    font-weight: 300;
  }

  @media only screen and (min-width: 870px) {
    width: calc(${Sizes.Wrapper} * 0.6);
    margin: 0 auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (min-width: 768px) and (max-width: 869px) {
    max-width: 70vw;
    margin: 0 auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (min-width: 601px) and (max-width: 767px) {
    max-width: 70vw;
    margin: calc(${Sizes.BaseUnit} * 2) auto calc(${Sizes.BaseUnit} * 3);
  }

  @media only screen and (max-width: 600px) {
    max-width: 85vw;
    margin: calc(${Sizes.BaseUnit} * 2) auto calc(${Sizes.BaseUnit} * 3);
  }
`;

import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import styled from 'styled-components'

import { Sizes } from '../../styles/Sizes'

import { InputBox } from '../styling-components/FORM/FormInput'
import { FormContainer } from '../styling-components/FORM/FormContainer'
import { ButtonDBG } from '../lego-static-parts/buttons/Button'

import Modal from '../lego-static-parts/Modal'

const ContactRow = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (min-width: 600px) {
    margin-bottom: ${ Sizes.IconGap };

    ${ InputBox } ~ ${ InputBox } {
      margin-left: ${ Sizes.IconGap };
    }
  }

  @media only screen and (max-width: 599px) {
    flex-direction: column;
    
    ${ InputBox } {
      margin-bottom: ${ Sizes.IconGap };
    }
  }
`

export const ContactBox = styled.div`
  margin: ${ Sizes.BaseUnit } auto 0;
  text-align: center;

  @media only screen and (min-width: 1601px) {
    width: 35vw;
  }

  @media only screen and (min-width: 1331px)and (max-width: 1600px) {
    width: 40vw;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1330px) {
    width: 45vw;
  }

  @media only screen and (min-width: 901px) and (max-width: 1200px) {
    width: 55vw;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    width: 75vw;
  }

  @media only screen and (min-width: 450px) and (max-width: 600px) {
    width: 80vw;
  }

  @media only screen and (max-width: 449px) {
    width: 85vw;
  }
`

const useMyModal = () => {
  /** Modal - Synlighet  */
  const [ showModal, setShowModal ] = useState(false);

  const openModal = () => { 
    setShowModal(true);
  }

  const closeModal = () => { 
    setShowModal(false);
  }

  return {
    showModal,
    openModal,
    closeModal
  }
}

export default function ContactForm(text) {
  const modal = useMyModal();

  /** Skickar Mail */
  const form = useRef();

  const sendMail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_g7o36ui', 'template_az38vng', form.current, 'bYjOAbC1sAOKi6Eit')
    .then((result) => {
      console.log(result.text);
      form.current.reset();
    }, (error) => {
      console.log(error.text);
    });
  }

  const sendMailForm = () => {
    modal.openModal();
  }

  return (
    <ContactBox>
      <h2 dangerouslySetInnerHTML={{ __html: text.h2item }} />
      <h4 dangerouslySetInnerHTML={{ __html: text.h4item }} />
      
      <FormContainer ref={ form } onSubmit={ sendMail }>
        <ContactRow>
          <InputBox>
            <input type='text' id='contactName' name='user_name' placeholder='Namn*' required/>
            <label htmlFor="contactName">Namn*</label>
          </InputBox>

          <InputBox>
            <input type='text' id='contactForetag' name='user_business' placeholder='Företag'/>
            <label htmlFor="contactForetag" placeholder='Företag'>Företag</label>
          </InputBox>
        </ContactRow>

        <ContactRow>
          <InputBox>
            <input type='email' id='contactMail' name='user_email' placeholder='Mail*' required/>
            <label htmlFor="contactMail" placeholder='Mail*'>Mail*</label>
          </InputBox>

          <InputBox>
            <input type='tel' id='contactTelefon' name='user_phone' placeholder='Telefon'/>
            <label htmlFor="contactTelefon" placeholder='Telefon'>Telefon</label>
          </InputBox>
        </ContactRow>

        <InputBox>
          <textarea id='contactMeddelande' name='message' placeholder='Meddelande*' required/>
          <label htmlFor="contactMeddelande" placeholder='Meddelande*'>Meddelande*</label>
        </InputBox>

        <ButtonDBG onClick={ sendMailForm }>Skicka</ButtonDBG>
      </FormContainer>
        
      <Modal showModal={modal.showModal} closeModal={modal.closeModal}/>

    </ContactBox>
  )
}
  
/*     
  const sendMailForm = () => {
    form.current.submit(); 
    modal.openModal();
  }

  MODAL-TEST:
    const modalTest = () => {
      modal.openModal();
    }
    
    <ButtonDBG onClick={ modalTest }>Bara modal</ButtonDBG> 
*/


  /** Gör 20MB-gräns:
   * <input type='file' id='bifogadfil' accept=".png, .jpg, .jpeg, .tiff, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, audio/*, video/*, image/*" placeholder='Bifoga en fil'/>
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
  */
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles/Colors';
import { Sizes } from '../../styles/Sizes';

import { ReactComponent as GM_White } from '../../images/GM-Symbol-White.svg';
import { LogoOGwhite, LogoOG } from '../lego-static-parts/LOGO/LogoOG';


const BrandMissionBox = styled.div`
    text-align: center;
    margin: 0 auto;

    p {
        color: ${ Colors.DarkBG };
        font-size: 18px;
        font-weight: 300;
        letter-spacing: .01rem;
        line-height: 1.6rem;

        margin-bottom: calc(${ Sizes.BaseUnit } *3);
    }

    @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: calc(${ Sizes.BaseUnit } *4.5) 0 calc(${ Sizes.BaseUnit } *5);
    }

    ${ LogoOG } {
        @media only screen and (min-width: 768px) {
            display: none;
        }
    }
`

const GMstamp = styled(GM_White)`
    color: ${ Colors.DarkBG };
    opacity: 70%;

    position: absolute;

    top: 50%;
    transform: translateY(-50%) translateX(-30%);

    @media only screen and (min-width: 1751px) {
        height: 175%;
        left: 0;
    }

    @media only screen and (min-width: 1701px) and (max-width: 1750px) {
        height: 168%;
        left: -1.5%;
    }

    @media only screen and (min-width: 1601px) and (max-width: 1700px) {
        height: 155%;
        left: -2.5%;
    }

    @media only screen and (min-width: 1461px) and (max-width: 1600px) {
        height: 146%;
        left: -3.8%;
    }

    @media only screen and (min-width: 1386px) and (max-width: 1460px) {
        height: 142%;
        left: -5.5%;
    }

    @media only screen and (min-width: 1341px) and (max-width: 1385px) {
        height: 140%;
        left: -5.5%;
    }

    @media only screen and (min-width: 1261px) and (max-width: 1340px) {
        height: 135%;
        left: -7%;
    }

    @media only screen and (min-width: 1211px) and (max-width: 1260px) {
        height: 125%;
        left: -7.5%;
    }

    @media only screen and (min-width: 1166px)  and (max-width: 1210px) {
        height: 122%;
        left: -8.5%;
    }

    @media only screen and (min-width: 1115px) and (max-width: 1165px) {
        height: 120%;
        left: -10%;
    }

    @media only screen and (min-width: 1080px) and (max-width: 1114px) {
        height: 117%;
        left: -12%;
    }

    @media only screen and (min-width: 1001px) and (max-width: 1079px) {
        height: 113%;
        left: -14%;
    }

    @media only screen and (min-width: 972px) and (max-width: 1000px) {
        height: 110%;
        left: -15%;
    }

    @media only screen and (min-width: 930px) and (max-width: 971px) {
        height: 107%;
        left: -16.5%;
    }

    @media only screen and (min-width: 900px) and (max-width: 929px) {
        height: 106%;
        left: -18%;
    }

    @media only screen and (min-width: 860px) and (max-width: 899px) {
        height: 104.5%;
        left: -18%;
    }

    @media only screen and (min-width: 820px) and (max-width: 859px) {
        height: 104%;
        left: -23%;
    }

    @media only screen and (min-width: 768px) and (max-width: 819px) {
        height: 102%;
        left: -27%;
    }

    @media only screen and (max-width: 767px) {
        display: none;
    }
`

const BrandMissionWrapper = styled.div`
    background-color: ${ Colors.Blue };
    overflow: hidden;
    position: relative;

    /** Desktop / Tablet - min 767px */
    @media only screen and (min-width: 1024px ) {
        ${ BrandMissionBox } {
            width: 40vw;
            max-width: calc( ${ Sizes.Wrapper } *0.5);
            padding: calc(${ Sizes.BaseUnit } *5) 0 calc(${ Sizes.BaseUnit } *5);
        }
    }

    @media only screen and (min-width: 911px) and (max-width: 1024px ) {
        ${ BrandMissionBox } {
            width: 45vw;
            padding: calc(${ Sizes.BaseUnit } *5) 0 calc(${ Sizes.BaseUnit } *5);
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 910px ) {
        ${ BrandMissionBox } {
            max-width: 55vw;
            padding: calc(${ Sizes.BaseUnit } *5) 0 calc(${ Sizes.BaseUnit } *4.5);
        }
    }

    /** ------------------------------------------------------------------------- */
    /** iPad Pro 12.9-inch - 6th generation - iOS 17.0 - 16GB */
    @media only screen and (orientation: portrait) and (min-device-width: 1024px) and (max-device-width: 1366px) {
        ${ GMstamp } {
            height: 113%;
            left: -22%;
        }

        ${ BrandMissionBox } {
            min-width: 60vw;
            max-width: 65vw;

            p {
                font-size: 20.5px;
                line-height: 1.7rem;
            }
        }
    }
    /** ------------------------ */

    /** iPad Pro 10.5" & 11" */
    @media only screen and (orientation: portrait) and (min-device-width: 834px) and (max-aspect-ratio: 3/4) {
        ${ GMstamp } {
            height: 115%;
            left: -33%;
        }

        ${ BrandMissionBox } {
            max-width: 58vw;

            p {
                font-size: 20.5px;
                line-height: 1.7rem;
            }
        }
    }

    @media only screen and (orientation: landscape) and (max-device-width: 834px) and (min-aspect-ratio: 3/4) {
        display: flex;
        justify-content: flex-end;

        ${ GMstamp } {
            height: 130%;
            left: -16%;
        }

        ${ BrandMissionBox } {
            max-width: 50vw;

            p {
                font-size: 20.5px;
                line-height: 1.7rem;
            }
        }
    }
    /** ------------------------------------------------------------------------- */
    /** Mobile - max 767px */
    @media only screen and (min-width: 621px) and (max-width: 767px ) {
        ${ BrandMissionBox } {
            max-width: 80vw;
            padding: calc(${ Sizes.BaseUnit } *4.2) 0 calc(${ Sizes.BaseUnit } *6);

            p {
                font-size: 17px;
                line-height: 1.6rem;
            }

            ${ LogoOG } {
                width: 30vw;
            }
        }
    }

    @media only screen and (min-width: 540px) and (max-width: 620px ) {
        ${ BrandMissionBox } {
            max-width: 80vw;
            padding: calc(${ Sizes.BaseUnit } *4.2) 0 calc(${ Sizes.BaseUnit } *6);

            p {
                font-size: 17px;
                line-height: 1.6rem;
            }

            ${ LogoOG } {
                width: 40vw;
            }
        }
    }

    @media only screen and (min-width: 486px) and (max-width: 539px ) {
        ${ BrandMissionBox } {
            max-width: 84vw;

            p {
                font-size: 19px;
                line-height: 1.8rem;
            }

            ${ LogoOG } {
                width: 45vw;
            }
        }
    }

    @media only screen and (min-width: 415px) and (max-width: 485px ) {
        ${ BrandMissionBox } {
            max-width: 86vw;

            p {
                font-size: 20px;
                line-height: 1.8rem;
            }

            ${ LogoOG } {
                width: 50vw;
            }
        }
    }

    @media only screen and (min-width: 361px) and (max-width: 414px) {
        ${ BrandMissionBox } {
            max-width: 86vw;

            p {
                font-size: 19.5px;
                line-height: 1.7rem;
            }

            ${ LogoOG } {
                width: 48vw;
            } 
        }
    }

    @media only screen and (max-width: 360px) {
        ${ BrandMissionBox } {
            max-width: 85vw;

            p {
                font-size: 17px;
                line-height: 1.65rem;
            }

            ${ LogoOG } {
                width: 50vw;
            }
        }
    }
`

export default function BrandMission() {
  return (
    <BrandMissionWrapper >
        <GMstamp/>

        <BrandMissionBox>
            <p>Vår vision är att vara den trygga företags&shy;partnern för ert före&shy;tag när ni vill hitta er unika röst och ut&shy;tryck genom design och varu&shy;märkes&shy;strategi. Vi tror på att skapa minnes&shy;värda upp&shy;lev&shy;elser för kund&shy;erna genom att kombi&shy;nera estet&shy;iskt till&shy;talande design med effektiv kom&shy;munikation. Genom att lyssna på våra kunders behov och värde&shy;ringar skapar vi varu&shy;märken som sticker ut i mängden och hjälper före&shy;tag att nå ut till sin mål&shy;grupp på ett sätt som inspirerar och enga&shy;gerar.</p>
            
            <LogoOGwhite/>
        </BrandMissionBox>
    </BrandMissionWrapper>
  )
}
import styled from "styled-components";
import { Sizes } from "../../../styles/Sizes";
import { Colors } from "../../../styles/Colors";

export const InputBox = styled.div`
    position: relative;
    width: 100%;

    input {
        width: 100%;
        height: calc(${ Sizes.BaseUnit } *3);
    }
  
    label {
        font-family: 'quasimoda', 'new-atten', 'apparat', 'quire-sans', 'proxima-nova', 'brandon-grotesque', 'montserrat', 'lato', '-apple-system', 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: .6rem;
        letter-spacing: .043rem;
        color: ${ Colors.GreyText };

        position: absolute;
        top: ${ Sizes.BaseUnit };
        left: 0.09rem;
        transform-origin: left center;
        transition: transform 250ms;

        padding-left: ${ Sizes.IconGap };
        padding-right: ${ Sizes.IconGap };
    }

    input:focus + label, input:not(:placeholder-shown) + label {
        transform: translateY(-80%) translateX(4%)  scale(0.70);
    }

    textarea:focus + label, textarea:not(:placeholder-shown) + label {
        transform: translateY(-80%) translateX(3%)  scale(0.70);
    }
`
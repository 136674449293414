import { useState } from 'react';
import styled, { keyframes } from 'styled-components'

import useToggleVisibleClickOutside from '../../hooks/useToggleVisibleClickOutside'

import HamburgerIcon from '../icons/Hamburger'
import HamburgerList from './HamburgerList'
import { Colors } from '../../styles/Colors';

const animationIn = keyframes`
    from {
        width: 0vw;
        background-color: transparent;
        opacity: 0%;
    }

    to {
        width: 100vw;
        background-color: ${ Colors.SecondaryBlue };
        opacity: 99%;
    }
`

const animationOut = keyframes`
    from {
        width: 100vw;
        background-color: ${ Colors.SecondaryBlue };
        opacity: 99%;
    }

    to {
        width: 0vw; 
        background-color: transparent;
        opacity: 0%;
    }
`

export const HamburgerMenuBox = styled.div`
    /** Properties för HamburgerMenuBox finns i Header.js */
`

const MenuRolloutBox = styled.div`
    height: 100%;
    position: fixed;
    z-index: 10400;
    top: 0;
    right: 0;

    overflow: hidden;

    animation: ${({isComponentVisible}) => (isComponentVisible ? animationIn : animationOut)} 1s 1;
    animation-fill-mode: both;
`

export default function HamburgerMenu() {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useToggleVisibleClickOutside(false);

    const [showMenu, setShowMenu] = useState(false);

    const handleClick = () => { 
        setIsComponentVisible(!isComponentVisible);
        setShowMenu(!showMenu);
    }

  return (
    <HamburgerMenuBox data-testid='HamburgerMenuBox' ref = { ref } isComponentVisible = { isComponentVisible } onClick={handleClick}>
        {showMenu &&
            <MenuRolloutBox showMenu = { showMenu } isComponentVisible = { isComponentVisible }>
                <HamburgerList />
            </MenuRolloutBox>
        }

        <HamburgerIcon data-testid='HamburgerIcon' isComponentVisible = { isComponentVisible }/>
    </HamburgerMenuBox>
  )
}

/** - Fixa hamburger-iconen på scroll! */
import React from "react";
import styled from "styled-components";
import { Sizes } from "../styles/Sizes";
import { ContactItem } from "../content-info/ContactH2H4";

import { Wrapper } from "../components/styling-components/Wrapper";
import ContactForm from "../components/main/ContactForm";

const OffertBox = styled.div`
  h4 {
    margin-bottom: calc( ${ Sizes.BaseUnit } *2);
  }
`;

export default function Offert() {
  return (
    <OffertBox>
      <Wrapper>
        <ContactForm h2item={ContactItem[7].h2} h4item={ContactItem[7].h4} />
      </Wrapper>
    </OffertBox>
  );
}

import React, { useState } from "react";
import styled from "styled-components";
import { Wrapper } from "../../styling-components/Wrapper";
import MobileDevice, { MobileDeviceWrapper } from "../MobileDevice";
import UpperSiljan from "./MobileDisplayComponentParts/UpperSiljan";
import { Sizes } from "../../../styles/Sizes";
import LowerSiljan from "./MobileDisplayComponentParts/LowerSiljan";
import { Adorn } from "../../styling-components/TEXT/Adorn";
import LowerMask, {
  LowerBGMask,
} from "./MobileDisplayComponentParts/LowerMask";

//import Clock from '../../../../node_modules/react-clock/dist/esm/Clock.js';

const MobileComponentWrapper = styled(Wrapper)`
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;

  position: absolute;
  top: 15px;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
`;

const TextContent = styled.div`
  position: absolute;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -50px;
  width: 100%;
  height: 100%;
  margin-right: calc(${Sizes.BaseUnit} * 2);

  ${Adorn} {
    font-size: 4.5rem;
    line-height: 3rem;
    text-align: center;
  }

  #HiddenText {
    position: absolute;
    right: 0;
    bottom: ${({ scrollPosition }) => (scrollPosition ? "-60px" : "-200px")};

    transition: bottom 1s;
    text-align: end;
  }

  p {
    font-weight: 300;
    text-align: center;
  }
`;

const MDwrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  position: relative;
  margin-top: -50px;

  height: ${({ scrollPosition }) => (scrollPosition ? "1400px" : "1054.7px")};
  width: 100vw;

  margin-bottom: -20px;

  transition: height 1s;

  ${LowerBGMask} {
    z-index: ${({ scrollZindex }) => (scrollZindex ? "500" : "1500")};
    transition: ${({ scrollZindex }) => (scrollZindex ? "z-index 2s" : "")};
  }

  @media only screen and (min-width: 1650px) {
    ${MobileComponentWrapper} {
      width: 55vw;
    }

    ${TextBlock} {
      max-width: 600px;
      min-height: 470px;
    }
  }
  @media only screen and (min-width: 1500px) and (max-width: 1650px) {
    ${MobileComponentWrapper} {
      width: 65vw;
    }
    ${TextBlock} {
      max-width: 600px;
      min-height: 470px;
    }
  }

  @media only screen and (min-width: 1281px) and (max-width: 1499px) {
    ${MobileComponentWrapper} {
      width: 70vw;
    }
    ${TextBlock} {
      max-width: 600px;
      min-height: 470px;
    }
  }

  @media only screen and (min-width: 1052px) and (max-width: 1280px) {
    ${MobileComponentWrapper} {
      width: 80vw;
    }
    ${TextBlock} {
      min-height: 470px;
    }
    ${Adorn} {
      font-size: 3.9rem;
    }
  }

  @media only screen and (min-width: 925px) and (max-width: 1051px) {
    ${MobileComponentWrapper} {
      width: 90vw;
    }
    ${TextBlock} {
      min-height: 470px;
    }
    ${Adorn} {
      font-size: 3.8rem;
    }
  }

  @media only screen and (min-width: 877px) and (max-width: 949px) {
    ${MobileComponentWrapper} {
      width: 95vw;
    }
    ${TextBlock} {
      min-height: 470px;
    }
    ${Adorn} {
      font-size: 3.8rem;
    }
    p {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 799px) and (max-width: 876px) {
    ${MobileComponentWrapper} {
      width: 98vw;
    }
    ${TextBlock} {
      min-height: 470px;
    }
    ${Adorn} {
      font-size: 3.6rem;
    }
    p {
      font-size: 13.5px;
    }
  }
  @media only screen and (min-width: 400px) and (max-width: 798px) {
    height: ${({ scrollPosition }) => (scrollPosition ? "1400px" : "1104.7px")};

    ${MobileComponentWrapper} {
     //flex-direction: row;
      top: 82px;
      width: 97vw;
    }
    ${TextBlock} {
      order: 1;
      max-width: 400px;
      transform: translateX(50%, 50%);
      margin-right: 0;
    }
    ${TextContent} {
      top: 0px;
    }
    ${Adorn}{
        line-height: 2.5rem;
    }
    p {
      margin-top: 95px;
    }
    #HiddenText {
      display: none;
    }
    ${MobileDeviceWrapper} {
      order: 2;
      position: absolute;
      top: 320px;
     //bottom: 500px;
    }
  }
  @media only screen and (max-width: 398px) {
    height: ${({ scrollPosition }) => (scrollPosition ? "1400px" : "1104.7px")};

    ${MobileComponentWrapper} {
     //flex-direction: row;
      top: 82px;
      width: 97vw;
    }
    ${TextBlock} {
      order: 1;
      max-width: 400px;
      transform: translateX(50%, 50%);
      margin-right: 0;
    }
    ${TextContent} {
      top: 0px;
    }
    ${Adorn}{
        line-height: 2.5rem;
    }
    p {
      margin-top: 90px;
    }
    #HiddenText {
      display: none;
    }
    ${MobileDeviceWrapper} {
      order: 2;
      position: absolute;
      top: 350px;
     //bottom: 500px;
    }
  }
`;

export default function MobileDeviceDisplayComponent() {
  const [scrollPosition, setScrollPosition] = useState(false);
  const [scrollZindex, setScrollZindex] = useState(false);

  const changeImgPosition = () => {
    if (window.scrollY >= 50) {
      setScrollPosition(true);
    } else {
      setScrollPosition(false);
    }
  };

  const changeZindex = () => {
    if (window.scrollY >= 95) {
      setScrollZindex(true);
    } else {
      setScrollZindex(false);
    }
  };

  window.addEventListener("scroll", changeImgPosition);
  window.addEventListener("scroll", changeZindex);

  return (
    <MDwrapper scrollPosition={scrollPosition} scrollZindex={scrollZindex}>
      <LowerMask scrollZindex={scrollZindex} />

      <MobileComponentWrapper>
        <TextBlock scrollPosition={scrollPosition}>
          <TextContent>
            <Adorn>Ge din närvaro på nätet en boost!</Adorn>
            <p>
              Vår expertis sträcker sig över webb&shy;ut&shy;veck&shy;ling, från
              modern design till kraft&shy;full funk&shy;tion&shy;a&shy;litet.
              Oav&shy;sett om du behöver en ny webb&shy;plats, en
              upp&shy;datering av den befint&shy;liga eller support med andra
              webb&shy;ut&shy;vecklings&shy;projekt, är vi redo att hjälpa dig.
              Med vår skräddar&shy;sydda app&shy;roach tar vi oss gärna an ditt
              projekt inom webb- och
              app&shy;lika&shy;tions&shy;ut&shy;veck&shy;ling. Med fokus på att
              lever&shy;era hög&shy;kva&shy;litativa lös&shy;ningar kan du känna
              dig trygg med att ditt digi&shy;tala projekt är i goda händer.
            </p>
          </TextContent>

          <Adorn id="HiddenText">Testa gärna timern! -&gt;</Adorn>
        </TextBlock>

        <MobileDevice />
      </MobileComponentWrapper>

      <LowerSiljan />

      <UpperSiljan />
    </MDwrapper>
  );
}

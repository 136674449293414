import React from "react";
import styled from "styled-components";
import { Sizes } from "../../styles/Sizes";
import { Colors, MobileColors } from "../../styles/Colors";
import BigTimer from "../lego-static-parts/MobileDeviceContent/BigTimer";
import SmallTimer from "../lego-static-parts/MobileDeviceContent/SmallTimer";
import { SavedTimers } from "../lego-static-parts/MobileDeviceContent/SavedTimers";
import SetUsedTimer from "../lego-static-parts/MobileDeviceContent/MobileHooks/SetUsedTimer";

export const MobileDeviceWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(${Sizes.BaseUnit} * 3) 0 calc(${Sizes.BaseUnit} * 6);
`;

const Mobile = styled.div`
  h1,
  h2 {
    font-family: lato, quire-sans, Outfit;
  }

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin: ${Sizes.BaseUnit} 0;
    color: ${MobileColors.TextColor};
    letter-spacing: 0.5pt;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: ${Sizes.ParagraphSpacing};

    color: ${MobileColors.TextColor};
  }

  h4 {
    font-size: 14px;
    line-height: 1rem;
    font-weight: 500;
  }

  position: relative;
  width: 375px;
  aspect-ratio: 9/19.5;
  margin: auto;
  border: 16px black solid;
  border-radius: 36px;
  padding: 24px 16px 0 16px;
  overflow: hidden;

  background-color: ${MobileColors.MainBG};

  ::before {
    content: "";
    display: block;
    width: 60%;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -45%);
    background: black;
    border-radius: 15px;
  }

  :after {
    content: "";
    z-index: 1400;
    display: block;
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${MobileColors.TextColor};
    opacity: 60%;
    border-radius: 10px;
  }
`;

const Navigation = styled.div`
  height: 66px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.75px ${Colors.GreyText} solid;
  margin-bottom: 22px;
`;

const PlusTimer = styled.div`
  h1 {
    font-size: 40px;
    font-weight: 400;
  }

  margin: 0;
  transform: translate(0%, -20%);
`;

const SmallTimerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MobileFooter = styled.div`
  height: 49pt;
`;

export default function MobileDevice() {
  return (
    <MobileDeviceWrapper>
      <Mobile>
        <Navigation>
          <h1>Timer</h1>
          <PlusTimer>
            <h1>+</h1>
          </PlusTimer>
        </Navigation>

        <BigTimer />

        <h3>My timers</h3>

        <SmallTimerWrapper>
          {Object.keys(SavedTimers).map((index) => 
            <SmallTimer key={index} setUsed={SetUsedTimer} h={SavedTimers[index].timeHMS.h} m={SavedTimers[index].timeHMS.m} s={SavedTimers[index].timeHMS.s} hkey={SavedTimers.timerKey}/>
          )}
        </SmallTimerWrapper>

        <MobileFooter />
      </Mobile>
    </MobileDeviceWrapper>
  );
}

export const Colors = {
  White: "#fefefe",
  WhiteOpacity: "rgba(254, 254, 254, 0.85)",
  Black: "#0F0407",
  BlackOpacity: "rgba(15, 4, 7, 0.6)",

  VivaMagenta: "#be3455",
  SecondaryMagenta: "#C54A63",
  ThirdMagenta: "#b6365f",

  Blue: "#2f4858",
  SecondaryBlue: "#445B69",
  ThirdBlue: "#637682",

  Grey: "#9ea2ac",

  /* ÄNDRA! */
  Primary: "#282c34",
  Secondary: "#61dafb",

  GreyText: "#9ea2ac",

  HeartButton: "#bdc1cb",

  ButtonHover: "#00A7DD",
  ButtonTextHover: "#C1FAFF",

  DropDown: "#fcfcfc",
  DropdownBorder: "rgba(117, 121, 130, 0.2)",

  DarkBG: "#eee",
  LightBG: "#2e2e2d",
};

export const LinkColor = {
  LinkDarkBG: Colors.White,
  LinkHoverDarkBG: Colors.SecondaryMagenta,
  LinkActiveDarkBG: Colors.VivaMagenta,
  LinkVisitedDarkBG: Colors.White,

  LinkLightBG: Colors.Black,
  LinkHoverLightBG: Colors.VivaMagenta,
  LinkActiveLightBG: Colors.SecondaryMagenta,
  LinkVisitedLightBG: Colors.Black,
};

export const ButtonColor = {
  DarkBG: Colors.VivaMagenta,
  HoverDarkBG: Colors.SecondaryMagenta,
  ActiveDarkBG: Colors.ThirdMagenta,
  VisitedDarkBG: Colors.VivaMagenta,

  LightBG: Colors.Blue,
  HoverLightBG: Colors.SecondaryBlue,
  ActiveLightBG: Colors.ThirdBlue,
  VisitedLightBG: Colors.Blue,

  Grey: Colors.Grey,
  GreyHover: Colors.Blue,
  GreyActive: Colors.ThirdBlue,
};

export const FormColor = {
  Border: Colors.ThirdBlue,
  HoverLightBG: Colors.VivaMagenta,
  Focus: Colors.SecondaryBlue,
};

export const MobileColors = {
  MainBG: "#f2f2f2", // "#ECF0F3", //"#fefefe"
  TextColor: "#a0a0a0",

  ObjectBG: "#f2f2f2", // "#eee",
  ObjectBorder: "rgba(20, 18, 18, 0.3)",

  DarkShadow: "5px 5px 10px rgba(20, 18, 18, 0.15)", // #A6B4C8
  LightShadow: "-5px -5px 10px rgba(255, 255, 255, 1)", // #FDFDFD

  OuterOuterDarkShadow: "20px 20px 40px -5px rgba(20, 18, 18, 0.07)", 
  InsetLightShadow: "-23px -23px 45px rgba(255, 255, 255, .6)", 

  InsetDarkShadow2: "26px 26px 48px rgba(20, 18, 18, 0.16)",
  InsetLightShadow2: "-20px -20px 43px rgba(255, 255, 255, 0.64)",

  //   DarkShadowOG: "10px 10px 24px rgba(166, 180, 200, 0.7)", // #A6B4C8
  //   LightShadowOG: "-12px -12px 20px rgba(255, 255, 255, 0.8)", // #FDFDFD
};

import { Wrapper } from "../components/styling-components/Wrapper";
import ContactForm from "../components/main/ContactForm";
import { ContactItem } from "../content-info/ContactH2H4";

export default function Contact() {
  return (
    <Wrapper>
      <ContactForm h2item={ContactItem[0].h2} h4item={ContactItem[0].h4} />
    </Wrapper>
  );
}

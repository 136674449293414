import React from "react";
import styled from "styled-components";
import { AiOutlineDown } from "react-icons/ai";
import { Sizes } from "../../styles/Sizes";

const ArrowBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;

  height: calc( ${ Sizes.BaseUnit } *2);
  width: calc( ${ Sizes.BaseUnit } *2);

  svg {
    transform: scale(1);
    height: calc( ${ Sizes.BaseUnit } *1.2);
    width: calc( ${ Sizes.BaseUnit } *1.2);
  }
`;

export default function Arrow() {
  return (
    <ArrowBox>
      <AiOutlineDown />
    </ArrowBox>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CompanyInfo } from "../../content-info/Company";
import { Colors, LinkColor } from "../../styles/Colors";
import { Sizes } from "../../styles/Sizes";
import { Line } from "../lego-static-parts/lines/Line";
import { Flex } from "../styling-components/CONTAINERS/Flex";
import { SH } from "../styling-components/TEXT/SmallHeading";
import { SHnumber } from "../styling-components/TEXT/SHnumber";
import { Wrapper } from "../styling-components/Wrapper";

const FooterLine = styled(Line)`
  background-color: ${Colors.White};

  @media only screen and (min-width: 601px) {
    margin-top: calc(${Sizes.BaseUnit} * 2);
    margin-bottom: ${Sizes.BaseUnit};
  }

  @media only screen and (max-width: 600px) {
    margin-top: calc(${Sizes.BaseUnit} * 1.2);
    margin-bottom: ${Sizes.IconGap};
  }
`;

const UFContainer = styled(Flex)`
  align-items: center;
  padding-bottom: ${Sizes.IconGap};

  opacity: 85%;

  a {
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.1em;

    color: ${LinkColor.LinkDarkBG};

    &:hover {
      color: ${LinkColor.LinkHoverDarkBG};
    }

    &:active {
      color: ${LinkColor.LinkActiveDarkBG};
    }
  }

  @media only screen and (min-width: 601px) {
    justify-content: space-between;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    a {
      font-size: 14px;
    }
  }
`;

const CopyBox = styled.div`
  display: flex;
  align-items: center;

  ${SHnumber} {
    color: ${Colors.White};
  }

  @media only screen and (min-width: 601px) {
    ${SHnumber} {
      font-size: 12px;
    }

    ${SH} {
      font-size: 12px;
      letter-spacing: 0.15em;
    }
  }

  @media only screen and (max-width: 600px) {
    ${SHnumber} {
      font-size: 14px;
    }

    ${SH} {
      font-size: 14px;
      letter-spacing: 0.15em;
    }
  }
`;

const Copy = styled.h2`
  color: ${Colors.White};

  font-size: 18px;
  font-weight: 300;
  letter-spacing: calc(${Sizes.IconGap} * 0.6);
`;

const Location = styled(SH)`
  text-transform: none;
`;

const LinkContainer = styled.div`
  display: flex;

  a {
    font-weight: 400;
  }

  @media only screen and (min-width: 601px) {
    column-gap: calc(${Sizes.IconGap}*2.5);
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: calc(${Sizes.IconGap} * 0.7);
  }

  a + a {
    @media only screen and (max-width: 600px) {
      margin-top: calc(${Sizes.IconGap}*.3);
    }
  }
`;

export default function UnderFooter() {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <FooterLine />

      <UFContainer>
        <CopyBox>
          <Copy>&copy;</Copy> <SHnumber>{year}</SHnumber>
          <SH>, {CompanyInfo.name} - </SH>{" "}
          <Location>&nbsp;{CompanyInfo.location}.</Location>
        </CopyBox>

        <LinkContainer>
          <Link to="/Privacy">Intigritetspolicy</Link>
          <Link to="/Cookies">Cookies</Link>
        </LinkContainer>
      </UFContainer>
    </Wrapper>
  );
}

import styled from "styled-components";

export const SoMeButton = styled.button`
    a {
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    background-color: transparent;
    border: none;

    svg {
        transform: scale(1);
        height: 1.5rem;
        width: 1.5rem;
    }

    #TikTokFooterLink {
        transform: scale(0.7);
    }
`